


.cc-social-share {

  padding-top: 10px;

  &__item {
	background: #ffffff;
	color: #ffffff;
	border-radius: 50%;
	margin-right: 0.6rem;

	> img {
	  opacity: 0.3;
	  @include transition($transition-base);
	  filter:none;
	  &:hover {
		filter: invert(66%) sepia(52%) saturate(506%) hue-rotate(61deg) brightness(97%) contrast(87%);
		@include transition($transition-base);
		opacity: 1;
	  }
	}

  }
}
