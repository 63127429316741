

#module-xipblog-single .blog_single_post {

  .post_content {
	h1,h2,h3,h4,h5 {
	  line-height: 1.3;
	  padding: 25px 0;
	}
	p {
	  line-height: 1.5;
	  padding-bottom: 20px;
	}
  }

  .post-header-inner {
	width: 100%;
	bottom: 0;
	position: absolute;
	background: #fff;
	left: 0;

	@include media-breakpoint-up(md) {
	  width: 80%;
	  bottom: 0;
	  position: absolute;
	  background: #fff;
	  left: 0;
	}

  }

  .post-header__title {
	color: #202020;
	text-align: left;
  }

}

.meta_author {
  color: #000000;
  font-weight: $font-weight-medium;
  display: flex;
  align-items: center;
}

.post_meta {
  display: flex;
  align-items: center;
}


.meta_author_avatar {
  overflow: hidden;
  margin-right: 16px;
  border-radius: 50px;
}

.blog-home {

  .post_content {

	z-index: 10;
  }



}


.post_perspective1 {
  @include media-breakpoint-up(md) {
	right: -10%;
	margin-right: 0%;
	//width: 90vw;
	position: relative;
  }

}

.post_perspective2 {
  background: #6BCC4F;
  color: #ffffff;
  @include border-radius(15px);
  padding: 30px;
  margin-top: -80px;


  @include media-breakpoint-up(md) {
	margin-top: -100px;
	width: calc(100% - 200px);
	padding: 40px;
	z-index:10;
	max-width: 700px;
  }

  .post_title {
	color: #ffffff;
	font-weight: $font-weight-bold;
	font-size: 35px;
  }

  .btn {
	background: #ffffff;
	color: #6BCC4F;
  }

}


.read_more a {
  font-size: 19px;
  font-weight: $font-weight-medium;
  color: #6BCC4F !important;
}




.post_title {
  font-weight: $font-weight-bold;
  font-size: 35px;
}




.post-header {
  &-inner {

	padding: 40px 30px;

	@include media-breakpoint-up(md) {
	  padding: 50px 30px;
	}




  }

  &__wrap {
	min-height: 350px;

	@include media-breakpoint-up(md) {
	  min-height: 500px;
	}
  }

  &__title {
	font-size: 35px;
	color: #ffffff;
	z-index: 50;

	@include media-breakpoint-up(md) {
	  font-size: 40px;
	  max-width: 650px;
	}
  }
}


