.b-faq {

  &__item {
		a.btn-link {
		  padding: 12px 0;
		}
  }

  &__body {
	margin-left: 40px;
  }

  .btn-link[aria-expanded="true"] {
   .b-faq__icon {
     background: #f27e1c;

     &:before {
	   -webkit-transform: rotate(270deg);
	   -moz-transform: rotate(270deg);
	   -ms-transform: rotate(270deg);
	   -o-transform: rotate(270deg);
	   transform: rotate(270deg);
     }

   }
}

  &__icon {
	text-align: center;
	border-radius: 50%;
	background: #5bbd3f;
	color: #ffffff;
    padding: 7px 10px;
    display: inline-block;
    margin-right: 10px;

	&:before {
	  font-family: 'in' !important;
	  speak: never;
	  font-style: normal;
	  font-weight: normal;
	  font-variant: normal;
	  text-transform: none;
	  line-height: 1;
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	  content: "\e910";
      font-size: .8rem;

      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
      display: block;

	}

  }

}