.b-main-header {
  position: relative;


  &__item {
	position: relative;
  }

  &__title {
	margin-top: 20px;
	@include smart-responsive('font-size',60,30);
	font-weight: $font-weight-bold;
	color: #303730;
  }

  &__subtitle {
	color: #9DA29B;
	font-weight: $font-weight-medium;

	h2, h3 {
	  margin-top: 10px;
	  @include smart-responsive('font-size',18,16);
	  font-weight: $font-weight-medium;
	  color: #9DA29B;
	}

	max-width: 550px;

  }

  &__content {
	position: absolute;
	height: 100%;
	z-index: 2;

	.btn {
	  margin-top: 30px;
	}

  }



  &__pagination {
	display: block;
	position: relative;
	text-align: left;

	.swiper-pagination-bullet {
	  background: #E4E8E3;
	  width: 18px;
	  height: 18px;
	  opacity: 1;
	  margin: 4px 4px !important;

	  &.swiper-pagination-bullet-active {
			background: #6BCC4F;
	  }

	}



  }

}


.slide-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #ffffff;
}
.slide-inner--image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: .04;
  //transform: scale(1);
  -webkit-transition: opacity .2s ease-in-out, transform .2s ease .2s;
  -moz-transition: opacity .2s ease-in-out, transform .2s ease .2s;
  transition: opacity .2s ease-in-out, transform .2s ease .2s;
}

.swiper-slide-duplicate-active .slide-inner--image,
.swiper-slide-active .slide-inner--image {
  opacity: 1;
  //transform: scale(1.05);
}

.slide-inner--info {
  position: absolute;
  min-height: 50px;
  bottom: 3em;
  color: #fff;
  z-index: 1;
  opacity: 0;
  transition: opacity .5s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.swiper-slide-active .slide-inner--info {
  opacity: 1;
  transition: opacity .6s ease .6s;
}
