#footer {
  position: relative;
  padding: 70px 0 50px;
  background: #ffffff;
  border-top: 1px solid #F2F2F2;

  h3 {
	font-size: 17px;
	text-transform: uppercase;
	font-weight: $font-weight-bold;

	@include media-breakpoint-down(sm) {
	  text-align: center;
	}

  }


  .b-links {

	margin-bottom: 20px;

	ul {
	  padding: 0;
	  flex-direction: column;

	  li {
		font-size: 15px;
		margin-bottom: 8px;
	  }

	}

  }




  p {
	line-height: 24px;
  }

  img {
	margin: 0;
  }

  a {
	color: #9DA29B;
  }


  .links {

	ul {
	  display: flex;
	  padding: 4px 0;
	}

	text-align: center;

	@include media-breakpoint-up(md) {
	  text-align: left;
	}

	li {
	  padding-right: 15px;

	  &:last-child {
		padding:0;
	  }
	}

	a {
	  font-size: 16px;
	  line-height: 20px;
	  padding: 0;
	  font-weight: $font-weight-medium;
	  display: block;
	  color: #9DA29B;
	  margin-bottom: 0px;
	  position: relative;

	  &:hover {
		color: #111111;
	  }

	}

  }

}


.footer-container {
  margin: 0;
  padding: 0;
  overflow: visible;
  box-shadow: none;

  .footer_top {
	position: relative;
	padding: 30px 0;
	background: $primary;
	@include media-breakpoint-up(md) {

	  padding: 45px 0;
	}

  }


  .footer_block {
	margin-bottom: 0.4rem;


	h3 {
	  font-size: 17px;
	  margin-bottom: 8px;
	  position: relative;
	  color: #ffffff;
	  text-transform: uppercase;
	  font-weight: $font-weight-normal;

	  @include media-breakpoint-up(md) {
		margin-bottom: 30px;

	  }

	}


  }

}


.footer-copy {
  color: #9DA29B;
}


.b-contact {
  background: #F27E1C;
  border-radius: 15px;

  @include media-breakpoint-up(md) {
	&__img {
	  margin-top: -50px;
	}
  }

  h3 {
	text-align: left !important;
	color: #FFCA9E;
  }

	h2 {
	  text-align: left !important;
	  color: #ffffff;
	}


  &__content {
	padding: 30px 20px;
	@include media-breakpoint-up(md) {
	padding: 50px 30px;
	}
  }

  .btn {
	border: 1px solid #ffffff;
	background: transparent;
	color: #ffffff !important;

	&:hover {
	  background: transparent;
	}

  }

}