body#checkout section.checkout-step {
  box-shadow: none;
  border: $main_border;
  margin-bottom: 10px;

  .tab-content {
	padding: 0;
	border: none;
  }
}

body#checkout section.checkout-step .step-title {
  text-transform: capitalize;
}

body#checkout .custom-checkbox span {
  margin-top: 0;
}

body#checkout section.checkout-step .address-item.selected {
  border: 2px solid #ebebeb;
}

body#checkout section.checkout-step .address-alias {
  vertical-align: -2px;
  font-size: 14px;
  margin-left: 5px;
}

body#checkout section.checkout-step .address {
  line-height: 24px;
}


.cart-summary {

  .btn-primary {
	color: #ffffff !important;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }

  border: 0;
  padding: 60px 0px 20px 0px;

  @include media-breakpoint-up(md) {
	padding: 30px;
	//box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 20px;
  }

  .cart-detailed-actions {
	padding: 0;
  }

  .cart-subtotal-products {
	font-size: 1rem;
  }

  .cart-detailed-actions {


	.btn-primary {
	  width: 100%;
	}
  }


  .cart-summary-line,
  .cart-total {
	padding: 4px 0;
  }

  .cart-total {
	.label,
	.value {
	  display: inline-block;
	  margin: 0;
	  font-size: 18px !important;
	  padding: 16px 0;
	  color: #000000;
	  font-weight: $font-weight-medium;
	}
  }

  //.cart-summary-line .label,
  //.cart-summary .label,
  //.cart-summary-line.cart-summary-subtotals .label,
  //.cart-summary-line.cart-summary-subtotals .value {
  //text-transform: capitalize;
  //}

  .cart-summary-line {
	.label,
	.value {
	  font-size: 1rem;
	}
  }

}


.block-promo {
  padding: 10px 0;
}


.permanent-warning {
  position: relative;
  background: $primary;
  color: #fff;
  margin-bottom: 15px !important;
  font-size: 0.8125rem;
  padding: 0.75rem 1.25rem !important;

}


.block-promo .promo-code {

  .alert-danger {
	position: relative;
	margin-top: 0;
	background: $primary;
	color: #fff;
	display: none;
	border: 0;
  }

  form {

	text-align: center;
  }

  .promo-input {
	border: 1px solid #e5e5e5;
	text-indent: 0.625rem;
  }


}


//.block-promo .cart-summary-line .label,
//.block-promo .promo-name {
//  color: #ff9a52;
//  font-weight: 700;
//}
.block-promo .cart-summary-line .label a,
.block-promo .promo-name a {
  font-weight: 400;
  color: #232323;
  display: inline-block;
}

.block-promo .promo-code-button {
  display: inline-block;
  color: $primary !important;
  font-weight: $font-weight-medium;
}

.block-promo.promo-highlighted {
  padding: 1.25rem;
  padding-bottom: 0;
  margin-bottom: 0;
}


.totalAmount {
  margin-top: 2rem;
}


.text-right {
  text-align: right;
}


//#customer_person_information_table {
//  tr td {
//
//	padding: 5px 0;
//
//  }
//}


.rewardsection {
  .promo-code-button a {
	font-weight: 700;
  }
}

#voucher-form {

  padding: 1rem 0;

  input[type="text"] {
	font-size: 15px;
	padding: 9px 20px;
  }

  button {
	min-height: 42px;
	color: #ffffff !important;
	font-weight: 700;
	font-size: .9rem;
	background: #202020;
  }

}


#columnleft-2 {
  background: #ffffff;
  padding: 15px;

  @include media-breakpoint-up(md) {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 20px;
	padding: 30px;
  }

}


#delivery_address_detail,
#invoice_address_detail {
  background: #f9f9f9;
}


.promo-name .cart-summary-line,
.rewardsection .cart_discount {

  padding: 6px 0;
  margin-bottom: 0.5rem;

  i {
	font-size: 0.8rem;
	padding: 4px;
	color: #202020;
  }
}

.input-different-shipping {
  padding-top: 0.6rem;
}


#buttonWithProgres {
  button {
	border: 0;
  }
}


.checkout-step {

  border-radius: 8px;
  overflow: hidden;

  .form-footer {
	display: table;
	margin-bottom: 10px;
  }

  &.-complete {
	.step-title {
	  background: lighten(#5fc27c, 38%);
	  color: #000000;
	  //border-bottom: 1px solid #efefef;
	}
  }

  .step-title {

	background: $primary;
	color: #ffffff;


	padding: 20px 20px;
	margin: 0;
  }

  .content {
	padding: 40px 25px;
  }
}


.cart-summary, #js-checkout-summary {
  padding: 15px;

  @include media-breakpoint-up(md) {
	padding: 30px;
  }

  box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 20px;
}

#cart-summary-product-list {
  font-size: 0.875rem;

  .product-name {
	font-size: 15px;
	font-weight: $font-weight-medium;
	color: #000000;
  }

  .product-price {
	margin: 5px 0 0;
	font-size: 16px;
	font-weight: $font-weight-medium;
  }

  img {
	width: 60px;
  }

  .media- {
	vertical-align: middle;
  }

}


.cart-summary-line {
  clear: both;
  margin: 10px 0;

  &.cart-summary-subtotals {

	.label {
	  font-size: 16px;
	}

	.value {
	  font-size: 16px;
	  font-weight: $font-weight-medium;
	}

  }

  &.cart-total {
	.label {
	  font-size: 16px;
	  font-weight: $font-weight-medium;
	}

	.value {
	  font-size: 16px;
	  font-weight: $font-weight-medium;
	}
  }


  &:after {
	content: "";
	display: table;
	clear: both;
  }

  .value {
	color: #000000;
	float: right;
  }

}


#checkout-personal-information-step {
  .content {
	p {
	  margin-bottom: 16px;
	}
  }
}

.address-selector {

  margin: 20px 0;

  .address-item {
	padding: 25px;
	border-radius: 10px;
  }
}

#checkout-addresses-step {
  a {
	font-weight: $font-weight-medium;
  }

  .edit-address,
  .delete-address {
	margin-right: 12px;
  }

  .add-address {
	margin: 10px 0;
  }
}

.carrier-price {
  font-weight: $font-weight-medium;
}


#checkout {
  color: #232323;
}

#checkout .custom-checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#checkout .custom-checkbox span {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15px;
  flex: 0 0 15px;
  margin-top: 0.1875rem;
}

#checkout section#content {
  margin-bottom: 1.563rem;
}

#checkout .container {
  min-height: 100%;
}


#checkout #content {
  margin-top: 50px;
}


.checkout-step {

  border-radius: 8px;
  overflow: hidden;

  .form-footer {
	display: table;
	margin-bottom: 10px;
  }

  &.-complete {
	.step-title {
	  background: lighten(#6bcc4f, 38%);
	  color: #000000;
	}
  }

  .step-title {
	background: #6bcc4f;
	color: #ffffff;
	padding: 20px 20px;
	font-size: 20px;
	font-weight: 600;
	margin: 0;
  }

  .content {

	padding: 25px 20px;

	@include media-breakpoint-up(md) {
	  padding: 35px 30px;
	}

  }
}


.checkout-step .step-edit {
  text-transform: lowercase;
  font-weight: 400;
}

.checkout-step .step-edit .edit {
  font-size: 1rem;
}

.checkout-step .not-allowed {
  cursor: not-allowed;
  opacity: 0.5;
}

.checkout-step .content,
.checkout-step .done,
.checkout-step .step-edit {
  display: none;
}

.checkout-step.-current .content {
  display: block;
}

.checkout-step.-current.-reachable.-complete .done,
.checkout-step.-current.-reachable.-complete .step-edit {
  display: none;
}

.checkout-step.-current.-reachable.-complete .step-number {
  display: inline-block;
}

.checkout-step.-current.-reachable.-complete .content {
  display: block;
}

.checkout-step.-reachable.-complete h1 .done {
  display: inline-block;
}

.checkout-step.-reachable.-complete h1 .step-number {
  display: none;
}

.checkout-step.-reachable.-complete h1 .step-edit {
  cursor: pointer;
  display: block;
  float: right;
  margin-right: 0.125rem;
  color: #7a7a7a;
}

.checkout-step.-reachable.-complete .content {
  display: none;
}

.checkout-step small {
  color: #7a7a7a;
}

.checkout-step .default-input {
  min-width: 40%;
}

.checkout-step .default-input[name="address1"],
.checkout-step .default-input[name="address2"] {
  min-width: 60%;
}

.checkout-step .radio-field {
  margin-top: 1.875rem;
}

.checkout-step .radio-field label {
  display: inline;
}

.checkout-step .checkbox-field div {
  margin-top: 3.75rem;
}

.checkout-step .checkbox-field + .checkbox-field div {
  margin-top: 0;
}

.checkout-step .select-field div {
  background: #f6f6f6;
  padding: 0.625rem 3.125rem;
}

.checkout-step .form-footer {
  text-align: center;
}

.checkout-step #conditions-to-approve {
  padding-top: 1rem;

  ul {
	padding-left: 20px;
  }

  li {
	display: flex;
  }

}

.payment-options {
  padding-top: 20px;
  padding-left: 20px;

  label {
	display: table-cell;
	margin:0;
  }

}


.checkout-step .payment-options .custom-radio {
  margin-right: 1.25rem;
}

.checkout-step .payment-options .payment-option {
  display: flex;
  margin-bottom: 0.5rem;
}

.checkout-step .step-number {
  display: inline-block;
  padding: 0.625rem;
}

.checkout-step .address-selector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.checkout-step .address-item {
  background: #f6f6f6;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 49%;
  flex: 0 0 49%;
  margin-bottom: 0.625rem;
  border: 3px solid transparent;
}

.checkout-step .address-item.selected {
  background: #fff;
  border: 3px solid $primary;
}

.checkout-step .address-alias {
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0.625rem;
}

.checkout-step .address {
  margin-left: 1.563rem;
  font-weight: 400;
}

.checkout-step .radio-block {
  padding: 0.9375rem;
  text-align: left;
  cursor: pointer;
}

.checkout-step .custom-radio {
  margin-right: 0;
}

.checkout-step .custom-radio input[type="radio"] {
  height: 1.25rem;
  width: 1.25rem;
}

.checkout-step .delete-address,
.checkout-step .edit-address {
  color: #7a7a7a;
  display: inline-block;
  margin: 0 0.3125rem;
}

.checkout-step .delete-address .delete,
.checkout-step .delete-address .edit,
.checkout-step .edit-address .delete,
.checkout-step .edit-address .edit {
  font-size: 1rem;
}

.checkout-step hr {
  margin: 0;
}

.checkout-step .address-footer {
  text-align: center;
  padding: 0.625rem;
}

.checkout-step #delivery-addresses,
.checkout-step #invoice-addresses,
.checkout-step .add-address {
  margin-top: 1.25rem;
}

.checkout-step .add-address a i {
  font-size: 0.9375rem;
}

.checkout-step .delivery-option {
  background: #f6f6f6;
  padding: 0.9375rem 0;
  margin-bottom: 0.9375rem;
}

.checkout-step .delivery-option label {
  text-align: inherit;
}

.checkout-step .carrier-delay,
.checkout-step .carrier-name {
  display: inline-block;
  word-break: break-word;
  text-align: left;
}

.checkout-step #customer-form,
.checkout-step #delivery-address,
.checkout-step #invoice-address,
.checkout-step #login-form {
  //margin-left: 0.3125rem;
  margin-top: 1.563rem;
}

.checkout-step #customer-form .form-control-label,
.checkout-step #delivery-address .form-control-label,
.checkout-step #invoice-address .form-control-label,
.checkout-step #login-form .form-control-label {
  text-align: left;
}

.checkout-step #customer-form .radio-inline,
.checkout-step #delivery-address .radio-inline,
.checkout-step #invoice-address .radio-inline,
.checkout-step #login-form .radio-inline {
  padding: 0;
}

section.checkout-step .sign-in {
  font-size: 0.875rem;
}


.checkout-step .delivery-option {
  padding: 1.2rem 0.5rem;
  background: #f7f7f7;
  border-radius: 8px;
  //margin-bottom: 0.9375rem;
}


.login-method {
  .nav-link {
	border-radius: 30px;
	background: #ffffff;
	border: 2px solid #f2f2f2;
	padding: 10px 25px;
	margin-right: 10px;

	&.active {
	  border-color: #6bcc4f;
	}

  }
}