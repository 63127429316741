.cc-block {

  $c: &;

  //margin: 100px 0;

  margin: 100px 0;

  @include media-breakpoint-down(md) {
	margin-top: 0;
	margin-bottom:0;
	margin-right: -($grid-gutter-width / 2);
	margin-left: -($grid-gutter-width / 2);
  }


  &__row {

	display: flex;
	flex-wrap: wrap;

	//@include media-breakpoint-up(md) {
	//
	//  margin-right: -($grid-gutter-width / 2);
	//  margin-left: -($grid-gutter-width / 2);
	//
	//}

  }

  &__label {
	font-weight: $font-weight-light;
	font-size: 16px;
	margin-bottom: 30px;
  }

  &__title {
	font-weight: $font-weight-medium;
	font-size: 35px;
  }


  &__content {

	//@include media-breakpoint-down(md) {
	//padding: 0;
	//}

  }

  &__desc {
	h1,h2,h3,h4,h5,h6 {
	  padding-top: 0.5rem;
	}
	p {
	  padding-bottom: 0.5rem;
	}
  }


  &__content-inner {

	padding: 100px 0;

	@include media-breakpoint-up(sm) {
	  padding: 100px 60px;
	}

  }


  &--2-col {

	  margin-right: -($grid-gutter-width / 2);
	  margin-left: -($grid-gutter-width / 2);

	#{$c}__elm {
	  @include media-breakpoint-down(sm) {
		padding-top: ($grid-gutter-width / 2);
		padding-bottom: ($grid-gutter-width / 2);
		padding-left: 0;
		padding-right: 0;
	  }
	}

	#{$c}__elm-inner {
	  @include media-breakpoint-up(sm) {
		position: absolute;
		bottom: -37px;
	  }
	}

	#{$c}__apla {
	  padding-right: ($grid-gutter-width / 2);
	  padding-left: ($grid-gutter-width / 2);
	  width: 100%;
	  position: relative;
	  height: 320px;
	  display: table;
	  z-index: 100;

	  @include media-breakpoint-up(sm) {
		min-width: 440px;
	  }

	  &-inner {
		vertical-align: middle;
		display: table-cell;
	  }



	  @include media-breakpoint-up(sm) {
		padding: 0 80px;
		margin-bottom: -37px;
		width: 60%;
	  }

	  #{$c}__label,
	  #{$c}__title {
		height: auto;
		opacity: 1;
		@include transition($transition-base);
	  }

	  #{$c}__title {
		//position: absolute;
	  }




	  #{$c}__title::after{
		display: block;
		width: 100%;
		height: 0;
		opacity:0;
		//font-size:0;
		@include transition($transition-base);
	  }




	  &:hover {
		#{$c}__label {
		  height:0;
		  opacity:0;
		  @include transition($transition-base);
		}

		#{$c}__title {


		  transform: translateY(-20px);
		  @include transition($transition-base);

		  &:after {
			margin-top: 20px;
			font-size: 12px;
			content: "\e916";
			font-family: 'in';
			color: #ffffff;
			height: auto;
			opacity: 1;
			@include transition($transition-base);
		  }
		}

	  }

	}

  }


  &--products {

	i {
	  font-size: 2rem;
	  color: #ffffff;
	}

	#{$c}__desc {
	  padding: 100px 0;

	  &-title {
		font-size: 35px;
		padding-bottom: 30px;
	  }


	}



	#{$c}__img-inner {
	  width: 100%;
	  padding-top: 100px;
	  padding-bottom: 100px;

	  padding-right: ($grid-gutter-width / 2);
	  padding-left: ($grid-gutter-width / 2);


	  background-size: cover;
	  background-repeat: no-repeat;
	  background-position: center center;


	  @include media-breakpoint-up(sm) {
		padding: 100px 60px;
	  }




	  #{$c}__desc {
		color: #ffffff;
		padding: 0;

		&-title {
		  font-size: 35px;
		  padding: 0px;
		}

	  }



	}




	#{$c}__title {
	  padding: 100px 0;
	}


  }


}