.b-category-slider {

  &__item {
	a {
	  position: relative;
	}

	h2 {
	  margin-bottom: 0;
	}
  }

  &__label {
	z-index: 10;
	margin-top: -30px;

	padding: 15px 30px;
	float: right;
	font-size: 1.25rem;

  }
}