.store-item {
  font-size: 15px;
  color: #1E1E1E;

  	strong {
	  font-weight: 600;
	}

  	&__title {
		font-size: 14px;
	  	color: #6A6A6A;
	  	margin-bottom: 10px;
	}

  	&__address {
		font-size: 22px;
	  	color: #1E1E1E;
	  margin-bottom: 8px;
	}




}