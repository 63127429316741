.b-about-us {
  padding-top: 60px;
  padding-bottom: 60px;
  line-height: 30px;


  &__content {
	padding-top: 40px;
	padding-bottom: 40px;
  }

  &__img img {
	width: calc(100% + 100px);
	position: relative;
	max-width: none;
	transform: translateX(-150px);
  }

  &__title {
	margin-top: 20px;
	font-size: 32px;
	color: #1E1E1E;
  }
}


.text-apla {
  z-index: 3;
  position: relative;
}


.image-apla {

  @include media-breakpoint-up(lg) {
	position: absolute;
	width: 50%;
	right: 0;
	top:0;
	transform: translateY(150px);
	//margin-bottom: 90px;
  }

  font-size: 16px;


  -webkit-border-top-left-radius: 20px;
  -webkit-border-bottom-right-radius: 20px;
  -moz-border-radius-topleft: 20px;
  -moz-border-radius-bottomright: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;

  //position: absolute;
  background: #EEEEEE;
  padding: 40px;
  z-index: 100;


}

.pr-lg {
  padding-right:0;
  @include media-breakpoint-up(lg) {
	padding-right: 50px;
  }
}

.pl-lg {
  padding-left:0;
  @include media-breakpoint-up(lg) {
	padding-left: 50px;
  }
}

//.image-apla::after {
//  content: "";
//  position: absolute;
//  left: 50%;
//  transform: translateX(-50%);
//  bottom: 100%;
//  width: 0;
//  height: 0;
//  border-top: 10px solid transparent;
//  border-right: 10px solid transparent;
//  border-left: 10px solid transparent;
//  border-bottom: 10px solid rgba(255, 220, 255, 0.8);
//}