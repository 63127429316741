/**
 * Left menu
 */

.c-left-menu--init {
  overflow-x: hidden;
  position: relative;
  top: 0;
  left: 0;
  @include transition(all .3s ease);
}

.c-left-menu--push {
  left: 320px;
  @include transition(all .3s ease);
}


.c-left-menu--opened {

  overflow: hidden;

  .c-left-menu {
	left: 0;
  }
}



.c-left-menu {
  background-color: #ffffff;
  top: 0;
  left: -320px;
  width: 320px;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling:touch;

  @include transition(all .3s ease);

  z-index: 400;
  -webkit-box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);

  ul {
	list-style: none;

	margin: 0;
	padding: 0;

	li {
	  width: 100%;

	  &:last-child {
		border: 0;
	  }

	  &.current a {
		color: #000000;
	  }


	  > a {
		color: #202020;
		font-size: 20px !important;
		padding: .3rem 1rem .3rem 1rem;
		display: block;
		font-weight: $font-weight-medium;
		line-height: 1.5;

		@include transition(all .25s ease-in-out);

		&:hover {
		  color: #000000;
		  @include transition(all .25s ease-in-out);
		}

	  }

	}

  }
}
///


.c-right-menu--init {
  overflow-y: hidden;
  -webkit-overflow-scrolling:touch;
  position: relative;
  top: 0;
  right: 0;
  @include transition(all .3s ease);
}

.c-right-menu--push {
  right: 400px;
  @include transition(all .3s ease);
}


.c-right-menu--opened {
  overflow: hidden;
  .c-right-menu {
	right: 0;
  }
}



.c-right-menu {
  //overflow-y: scroll;
  background-color: #ffffff;
  top: 0;
  right: -400px;
  width: 320px;
  height: 100%;

  @include transition(all .3s ease);

  z-index: 400;
  -webkit-box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);


  .shop-item {
	display: none !important;
  }

  .content-cart > ul {
	max-height: calc(100% - 50px);
	height: calc(100vh - 300px);
	width: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling:touch;
  }


  ul {
	list-style: none;

	margin: 0;
	padding: 0;

	li {
	  width: 100%;

	  &.current a {
		color: #000000;
	  }


	  > a {

		color: #575757;
		font-size: 1.1em;
		padding: .7em 1.6em .7em 1.6em;
		display: block;
		font-weight: 500;

		@include transition(all .25s ease-in-out);

		&:hover {
		  color: #000000;
		  @include transition(all .25s ease-in-out);
		}

	  }

	}

  }
}


.m-close {
  border: 0;
  right: 0;
  padding: 20px;
  cursor: pointer;
  background-color: transparent;
}