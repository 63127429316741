@font-face {
  font-family: 'in';
  src:
  url('../fonts/in.ttf?bg2np1') format('truetype'),
  url('../fonts/in.woff?bg2np1') format('woff'),
  url('../fonts/in.svg?bg2np1#tb') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


i[class^="icon-"], i[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'in' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.icon-phone1:before {
  content: "\e900";
}
.icon-news:before {
  content: "\e901";
}
//.icon-menu:before {
//  content: "\e902";
//}
.icon-arrow_up:before {
  content: "\e90e";
}
.icon-close:before {
  content: "\e914";
}
.icon-phone:before {
  content: "\e903";
}
.icon-search2:before {
  content: "\e904";
}
.icon-truck:before {
  content: "\e905";
}
.icon-download:before {
  content: "\e906";
}
.icon-fav:before {
  content: "\e907";
}
.icon-plus:before {
  content: "\e908";
}
.icon-share:before {
  content: "\e909";
}
.icon-mark:before {
  content: "\e90a";
}
.icon-send:before {
  content: "\e90b";
}
.icon-cart:before {
  content: "\e90c";
}
.icon-search:before {
  content: "\e90d";
}
.icon-free-shipping:before {
  content: "\e90f";
}
.icon-chevron-right:before,
.icon-right:before {
  content: "\e910";
}
.icon-chevron-left:before,
.icon-left:before {
  content: "\e911";
}
.icon-arrow2:before {
  content: "\e916";
}
.icon-download2:before {
  content: "\e912";
}
.icon-check:before {
  content: "\e913";
}
.icon-star:before {
  content: "\e915";
}

.icon-fav2:before {
  content: "\e917";
}



.icon-chevron-small-up {
  content: "\e913";
}

.icon-chevron-small-down {
  content: "\e913";
}