
.btn {
  font-weight: $font-weight-medium;
  border-radius: 0;
  border: 0;
  line-height: 1.1;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &:hover, &:focus, &:active, &.active {
	outline: 0 !important;
	text-decoration: none !important;
  }

}


.btn-default {
  padding: 0 30px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  font-size: 13px;
  border: none;
  font-weight: 700;
  display: block;
  line-height: 38px;
  height: 38px;
  background: #6BCC4F;
  width: 100%;
  color: #ffffff !important;
  @include border-radius(30px);

  &.disabled, &:disabled {
	background: darken(#6BCC4F, 30%) !important;
  }

  &:hover {
	color: #ffffff;
	background: darken(#6BCC4F, 20%);
  }
}


.btn-small {
  padding: 4px 25px;
  z-index: 1;
  font-size: 0.85rem !important;
  text-transform: uppercase;
  font-weight: $font-weight-medium;
  display: table;
  box-shadow: none;
  border-radius: 2px;
  @include border-radius(30px);

  background: #9DA29B;
  color: #FFFFFF;


  &:hover {
	color: #FFFFFF;
	background: darken(#9DA29B, 20%);
  }

}

//
//.btn-order {
//  padding: 10px 25px;
//  cursor: pointer;
//  position: relative;
//  z-index: 1;
//  font-size: 1.5rem;
//  text-transform: uppercase;
//  border: none;
//  display: block;
//  background: $primary;
//  width: 100%;
//  color: #ffffff !important;
//
//  &.disabled, &:disabled {
//	background: darken($primary, 15%) !important;
//  }
//
//  &:hover {
//	background: lighten($primary, 10%);
//  }
//
//}


.btn-primary {
  padding: 15px 22px;
  font-size: 1rem;
  display: table;
  font-weight: $font-weight-medium;
  background: #6BCC4F;
  color: #ffffff;
  @include border-radius(30px);

  @include media-breakpoint-up(sm) {
	padding: 15px 30px;
  }

  &.disabled, &:disabled {
	opacity: 0.6;
	cursor: not-allowed;
  }

  &:hover {
	color: #ffffff;
	background: darken(#6BCC4F, 20%);
  }

}


.btn-secondary {
  font-size: 1rem;
  font-weight: $font-weight-medium;
  display: table;
  padding: 15px 22px;
  background: #9DA29B;
  color: #FFFFFF;
  @include border-radius(30px);


  i {
	font-size: 0.9rem;
	margin-right: 0.2rem;
  }

  &:hover {
	color: #FFFFFF;
	background: darken(#9DA29B, 20%);
  }

}


.dropdown-toggle {
  padding: 20px 40px;
  font-size: 16px;
  font-weight: $font-weight-medium;
  display: table;
  border: 2px solid #efefef;
  color: #000000;
  background: #ffffff;
  border-radius: 90px;
  text-align: left;
  width: 100%;
  margin-bottom: 16px;

  &:focus {
	border-color: inherit;
	-webkit-box-shadow: none;
	box-shadow: none;
  }

  i {
	opacity: 0.5;
	font-size: 0.9rem;
	margin-right: 0.2rem;
  }

  &:hover {
	border: 2px solid #000000;
  }

  &:after {
	right: 25px;
	position: absolute;
	top: 25px;
  }

}