.b-create-cake {
  padding-top: 50px;

  &__intro {
	background: #1E1E1E;
	height: 500px;
	position: relative;
  }

  &__wrap {
	margin-top: -30px;
	background: #F9F9F9
  }

  &__item {
	background: #F9F9F9;
	z-index: 10;
	margin-bottom: 20px;

	&-inner {
	  padding: 30px 12px;
	}

  }

  &__icon {
	margin-right: 20px;
  }

  &__title {
	@include smart-responsive('font-size', 21, 18);
	font-weight: $font-weight-normal;
	margin-bottom: 8px;
  }

  &__desc {
	font-size: 13px;
	color: #6A6A6A;
  }

  &__mask {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 5;
	opacity: .7;
	background: #1E1E1E;
  }


}

