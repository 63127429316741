
a {
  color: $primary;
  text-decoration: none;
}

a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: none;
}


[role="button"] {
  cursor: pointer;
}

[role="button"],
a,
area,
button,
input,
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}


.col-form-legend,
.form-control-static {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.form-control-static {
  line-height: 1.25;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-static.form-control-lg,
.form-control-static.form-control-sm,
.input-group-lg > .form-control-static.form-control,
.input-group-lg > .form-control-static.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-static.btn,
.input-group-sm > .form-control-static.form-control,
.input-group-sm > .form-control-static.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-static.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]),
.input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
select.form-control-sm:not([size]):not([multiple]) {
  height: 1.8125rem;
}

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  padding: 0.75rem 1.5rem;
  font-size: 0.9375rem;
  border-radius: 0.3rem;
}

.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
  height: 2.75rem;
}

.has-success .custom-control,
.has-success .form-check-inline,
.has-success .form-check-label,
.has-success .form-control-feedback,
.has-success .form-control-label {
  color: #4cbb6c;
}

.has-success .form-control {
  border-color: #4cbb6c;
}

.has-success .form-control:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #96d7a9;
}

.has-success .input-group-addon {
  color: #4cbb6c;
  border-color: #4cbb6c;
  background-color: #e0f3e5;
}

.has-success .form-control-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#4cbb6c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}

.has-warning .custom-control,
.has-warning .form-check-inline,
.has-warning .form-check-label,
.has-warning .form-control-feedback,
.has-warning .form-control-label {
  color: #ff9a52;
}

.has-warning .form-control {
  border-color: #ff9a52;
}

.has-warning .form-control:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffd6b8;
}

.has-warning .input-group-addon {
  color: #ff9a52;
  border-color: #ff9a52;
  background-color: #fff;
}

.has-warning .form-control-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#ff9a52' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
}

.has-danger .custom-control,
.has-danger .form-check-inline,
.has-danger .form-check-label,
.has-danger .form-control-feedback,
.has-danger .form-control-label {
  color: #ff4c4c;
}

.has-danger .form-control {
  border-color: #ff4c4c;
}

.has-danger .form-control:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffb2b2;
}

.has-danger .input-group-addon {
  color: #ff4c4c;
  border-color: #ff4c4c;
  background-color: #fff;
}

.has-danger .form-control-danger {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#ff4c4c' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
}


a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}


.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-info.focus,
.btn-info:focus,
.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aabd2;
}

.btn-info.active,
.btn-info:active,
.open > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aabd2;
  background-image: none;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info:active.focus,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .btn-info.dropdown-toggle.focus,
.open > .btn-info.dropdown-toggle:focus,
.open > .btn-info.dropdown-toggle:hover {
  color: #fff;
  background-color: #269abc;
  border-color: #1f7e9a;
}

.btn-info.disabled.focus,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info:disabled.focus,
.btn-info:disabled:focus,
.btn-info:disabled:hover {
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-success {
  color: #fff;
  background-color: #4cbb6c;
  border-color: #4cbb6c;
}

.btn-success.focus,
.btn-success:focus,
.btn-success:hover {
  color: #fff;
  background-color: #3a9a56;
  border-color: #389252;
}

.btn-success.active,
.btn-success:active,
.open > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #3a9a56;
  border-color: #389252;
  background-image: none;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .btn-success.dropdown-toggle.focus,
.open > .btn-success.dropdown-toggle:focus,
.open > .btn-success.dropdown-toggle:hover {
  color: #fff;
  background-color: #318047;
  border-color: #256237;
}

.btn-success.disabled.focus,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success:disabled.focus,
.btn-success:disabled:focus,
.btn-success:disabled:hover {
  background-color: #4cbb6c;
  border-color: #4cbb6c;
}

.btn-warning {
  color: #fff;
  background-color: #ff9a52;
  border-color: #ff9a52;
}

.btn-warning.focus,
.btn-warning:focus,
.btn-warning:hover {
  color: #fff;
  background-color: #ff7c1f;
  border-color: #ff7615;
}

.btn-warning.active,
.btn-warning:active,
.open > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ff7c1f;
  border-color: #ff7615;
  background-image: none;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning:active.focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .btn-warning.dropdown-toggle.focus,
.open > .btn-warning.dropdown-toggle:focus,
.open > .btn-warning.dropdown-toggle:hover {
  color: #fff;
  background-color: #fa6800;
  border-color: #d25700;
}

.btn-warning.disabled.focus,
.btn-warning.disabled:focus,
.btn-warning.disabled:hover,
.btn-warning:disabled.focus,
.btn-warning:disabled:focus,
.btn-warning:disabled:hover {
  background-color: #ff9a52;
  border-color: #ff9a52;
}

.btn-danger {
  color: #fff;
  background-color: #ff4c4c;
  border-color: #ff4c4c;
}

.btn-danger.focus,
.btn-danger:focus,
.btn-danger:hover {
  color: #fff;
  background-color: #ff1919;
  border-color: #ff0f0f;
}

.btn-danger.active,
.btn-danger:active,
.open > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff1919;
  border-color: #ff0f0f;
  background-image: none;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active.focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .btn-danger.dropdown-toggle.focus,
.open > .btn-danger.dropdown-toggle:focus,
.open > .btn-danger.dropdown-toggle:hover {
  color: #fff;
  background-color: #f40000;
  border-color: #c00;
}

.btn-danger.disabled.focus,
.btn-danger.disabled:focus,
.btn-danger.disabled:hover,
.btn-danger:disabled.focus,
.btn-danger:disabled:focus,
.btn-danger:disabled:hover {
  background-color: #ff4c4c;
  border-color: #ff4c4c;
}


.btn-link {
  font-weight: 400;
  color: $primary;
  border-radius: 0;
}

.btn-link,
.btn-link.active,
.btn-link:active,
.btn-link:disabled {
  background-color: transparent;
}

.btn-link,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
  border-color: transparent;
}

.btn-link:focus,
.btn-link:hover {
  color: #208094;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link:disabled:focus,
.btn-link:disabled:hover {
  color: #f1f1f1;
  text-decoration: none;
}


.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.fade.in, .fade.show {
  opacity: 1;
}



tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing,
.dropup {
  position: relative;
}

.open > .dropdown-menu {
  display: block;
}

.open > a {
  outline: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #f1f1f1;
  white-space: nowrap;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.dropup .caret {
  content: "";
  border-top: 0;
  border-bottom: 0.3em solid;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 0.125rem;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.btn-group > .btn {
  position: relative;
  float: left;
  margin-bottom: 0;
}


.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 2;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  margin-left: -0.5rem;
}

.btn-toolbar:after {
  content: "";
  display: table;
  clear: both;
}

.btn-toolbar .btn-group,
.btn-toolbar .input-group {
  float: left;
}

.btn-toolbar > .btn,
.btn-toolbar > .btn-group,
.btn-toolbar > .input-group {
  margin-left: 0.5rem;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.btn-group > .btn-group {
  float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}

.btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn + .dropdown-toggle-split:after {
  margin-left: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn .caret {
  margin-left: 0;
}

.btn-group-lg > .btn .caret,
.btn-lg .caret {
  border-width: 0.3em 0.3em 0;
  border-bottom-width: 0;
}

.dropup .btn-group-lg > .btn .caret,
.dropup .btn-lg .caret {
  border-width: 0 0.3em 0.3em;
}


[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  width: 100%;
  display: table;
  border-collapse: separate;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

.input-group .form-control:active,
.input-group .form-control:focus,
.input-group .form-control:hover {
  z-index: 3;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell;
}

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #7a7a7a;
  text-align: center;
  background-color: #f6f6f6;
  border-radius: 0;
}

.input-group-addon.form-control-sm,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .input-group-addon.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-addon.form-control-lg,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .input-group-addon.btn {
  padding: 0.75rem 1.5rem;
  font-size: 0.9375rem;
  border-radius: 0.3rem;
}

.input-group-addon input[type="checkbox"],
.input-group-addon input[type="radio"] {
  margin-top: 0;
}

.input-group-addon:not(:last-child),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group .form-control:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group-addon:not(:last-child) {
  border-right: 0;
}

.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group .form-control:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0;
}

.input-group-btn {
  font-size: 0;
  white-space: nowrap;
}

.input-group-btn,
.input-group-btn > .btn {
  position: relative;
}

.input-group-btn > .btn + .btn {
  margin-left: -1px;
}

.input-group-btn > .btn:active,
.input-group-btn > .btn:focus,
.input-group-btn > .btn:hover {
  z-index: 3;
}

.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group {
  margin-right: -1px;
}

.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group {
  z-index: 2;
  margin-left: -1px;
}

.input-group-btn:not(:first-child) > .btn-group:active,
.input-group-btn:not(:first-child) > .btn-group:focus,
.input-group-btn:not(:first-child) > .btn-group:hover,
.input-group-btn:not(:first-child) > .btn:active,
.input-group-btn:not(:first-child) > .btn:focus,
.input-group-btn:not(:first-child) > .btn:hover {
  z-index: 3;
}

.custom-control {
  position: relative;
  display: inline-block;
  padding-left: 1.5rem;
  cursor: pointer;
}

.custom-control + .custom-control {
  margin-left: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #0074d9;
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #0074d9;
}

.custom-control-input:active ~ .custom-control-indicator {
  color: #fff;
  background-color: #84c6ff;
}

.custom-control-input:disabled ~ .custom-control-indicator {
  cursor: not-allowed;
  background-color: #eee;
}

.custom-control-input:disabled ~ .custom-control-description {
  color: #767676;
  cursor: not-allowed;
}

.custom-control-indicator {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-indicator {
  border-radius: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #0074d9;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-indicator {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#fff'/%3E%3C/svg%3E");
}

.custom-controls-stacked .custom-control {
  float: left;
  clear: left;
}

.custom-controls-stacked .custom-control + .custom-control {
  margin-left: 0;
}

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(2.5rem - 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  padding-right: 0.75rem \9
;
  color: #7a7a7a;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-image: none \9
;
  background-size: 8px 10px;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.custom-select:focus {
  border-color: #51a7e8;
  outline: none;
}

.custom-select:focus::-ms-value {
  color: #7a7a7a;
  background-color: #fff;
}

.custom-select:disabled {
  color: #f1f1f1;
  cursor: not-allowed;
  background-color: #f6f6f6;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}


.navbar-toggler {
  width: 2.5em;
  height: 2em;
  padding: 0.5rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1;
  background: transparent no-repeat 50%;
  background-size: 24px 24px;
  border: 1px solid transparent;
  border-radius: 0;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}


.navbar-nav .nav-item {
  float: left;
}

.navbar-nav .nav-link {
  display: block;
  padding-top: 0.425rem;
  padding-bottom: 0.425rem;
}

.navbar-nav .nav-item + .nav-item,
.navbar-nav .nav-link + .nav-link {
  margin-left: 1rem;
}

.navbar-toggleable-xs:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 575px) {
  .navbar-toggleable-xs .navbar-nav .nav-item {
	float: none;
	margin-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-toggleable-xs {
	display: block !important;
  }
}

.navbar-toggleable-sm:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 767px) {
  .navbar-toggleable-sm .navbar-nav .nav-item {
	float: none;
	margin-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-toggleable-sm {
	display: block !important;
  }
}

.navbar-toggleable-md:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 991px) {
  .navbar-toggleable-md .navbar-nav .nav-item {
	float: none;
	margin-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-toggleable-md {
	display: block !important;
  }
}

.card {
  position: relative;
  display: block;
  margin-bottom: 0.75rem;
}


.card-block:after {
  content: "";
  display: table;
  clear: both;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
}

.card-subtitle,
.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}


.pagination {
  display: inline-block;
  padding-left: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;

  li {
    padding: 4px 8px;
  }
}

.page-item {
  display: inline;
}


.alert {
  padding: 0.75rem 1.15rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
  font-weight: 500;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 2.5rem;
}

.alert-dismissible .close {
  position: relative;
  top: -0.125rem;
  right: -1.25rem;
  color: inherit;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d0e9c6;
  color: #3c763d;
}

.alert-success hr {
  border-top-color: #c1e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  color: #202020;
}

.alert-warning {
  background-color: #fde8e8;
  border-color: #ddb6b6;
  color: #242121;
}

.alert-warning hr {
  border-top-color: #ff8b39;
}

.alert-warning .alert-link {
  color: #0a0a0a;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebcccc;
  color: #a94442;
}

.alert-danger hr {
  border-top-color: #e4b9b9;
}

.alert-danger .alert-link {
  color: #843534;
}


.media,
.media-body {
  overflow: hidden;
}

.media-body {
  width: 10000px;
}

.media-body,
.media-left,
.media-right {
  display: table-cell;
  vertical-align: top;
}

.media-middle {
  vertical-align: middle;
}

.media-bottom {
  vertical-align: bottom;
}

.media-object {
  display: block;
}

.media-object.img-thumbnail {
  max-width: none;
}

.media-right {
  padding-left: 10px;
}

.media-left {
  padding-right: 10px;
}

.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.media-list {
  padding-left: 0;
  list-style: none;
}


.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
}

.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal,
.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  outline: 0;
}

//.modal.fade .modal-dialog {
//  transition: -webkit-transform 0.3s ease-out;
//  transition: transform 0.3s ease-out;
//  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
//  -webkit-transform: translateY(-25%);
//  transform: translateY(-25%);
//}

.modal.in .modal-dialog {
  -webkit-transform: translate(0);
  transform: translate(0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0;
}

.modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}


.modal-backdrop.fade.in {
  opacity: 0.65;
}


//.modal-backdrop.show {
//  opacity: 0.5;
//}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.modal-header:after {
  content: "";
  display: table;
  clear: both;
}

.modal-title {
  margin: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  padding: 30px;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.modal-footer:after {
  content: "";
  display: table;
  clear: both;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
	max-width: 600px;
	margin: 30px auto;
  }
  .modal-sm {
	max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
	max-width: 900px;
  }
}


@media (min-width: 576px) {


  .bg-faded {
	background-color: #f7f7f9;
  }
  .bg-primary {
	background-color: $primary !important;
  }
  a.bg-primary:focus,
  a.bg-primary:hover {
	background-color: #2592a9 !important;
  }
  .bg-success {
	background-color: #4cbb6c !important;
  }
  a.bg-success:focus,
  a.bg-success:hover {
	background-color: #3a9a56 !important;
  }
  .bg-info {
	background-color: #5bc0de !important;
  }
  a.bg-info:focus,
  a.bg-info:hover {
	background-color: #31b0d5 !important;
  }
  .bg-warning {
	background-color: #ff9a52 !important;
  }
  a.bg-warning:focus,
  a.bg-warning:hover {
	background-color: #ff7c1f !important;
  }
  .bg-danger {
	background-color: #ff4c4c !important;
  }
  a.bg-danger:focus,
  a.bg-danger:hover {
	background-color: #ff1919 !important;
  }
  .bg-inverse {
	background-color: #373a3c !important;
  }
  a.bg-inverse:focus,
  a.bg-inverse:hover {
	background-color: #1f2021 !important;
  }

  .clearfix:after {
	content: "";
	display: table;
	clear: both;
  }
  .d-block {
	display: block !important;
  }
  .d-inline-block {
	display: inline-block !important;
  }
  .d-inline {
	display: inline !important;
  }
  .float-xs-left {
	float: left !important;
  }
  .float-xs-right {
	float: right !important;
  }
  .float-xs-none {
	float: none !important;
  }

}

//@media (min-width: 576px) {
//.float-sm-left {
//  float: left !important;
//}
//.float-sm-right {
//  float: right !important;
//}
//.float-sm-none {
//  float: none !important;
//}
//}
//@media (min-width: 768px) {
//.float-md-left {
//  float: left !important;
//}
//.float-md-right {
//  float: right !important;
//}
//.float-md-none {
//  float: none !important;
//}
//}
//@media (min-width: 992px) {
//.float-lg-left {
//  float: left !important;
//}
//.float-lg-right {
//  float: right !important;
//}
//.float-lg-none {
//  float: none !important;
//}
//}
//@media (min-width: 1200px) {
//.float-xl-left {
//  float: left !important;
//}
//.float-xl-right {
//  float: right !important;
//}
//.float-xl-none {
//  float: none !important;
//}
//}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 1rem !important;
}

.mx-1 {
  margin-right: 1rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-2 {
  margin: 1.5rem !important;
}

.mt-2 {
  margin-top: 1.5rem !important;
}

.mr-2 {
  margin-right: 1.5rem !important;
}

.mb-2 {
  margin-bottom: 1.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 1.5rem !important;
}

.mx-2 {
  margin-right: 1.5rem !important;
}

.my-2 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-3 {
  margin: 3rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 3rem !important;
}

.mx-3 {
  margin-right: 3rem !important;
}

.my-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: 1rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pl-1,
.px-1 {
  padding-left: 1rem !important;
}

.px-1 {
  padding-right: 1rem !important;
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-2 {
  padding: 1.5rem !important;
}

.pt-2 {
  padding-top: 1.5rem !important;
}

.pr-2 {
  padding-right: 1.5rem !important;
}

.pb-2 {
  padding-bottom: 1.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 1.5rem !important;
}

.px-2 {
  padding-right: 1.5rem !important;
}

.py-2 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-3 {
  padding: 3rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.pl-3,
.px-3 {
  padding-left: 3rem !important;
}

.px-3 {
  padding-right: 3rem !important;
}

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pos-f-t {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-xs-left {
  text-align: left !important;
}

.text-xs-right {
  text-align: right !important;
}

.text-xs-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
	text-align: left !important;
  }
  .text-sm-right {
	text-align: right !important;
  }
  .text-sm-center {
	text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
	text-align: left !important;
  }
  .text-md-right {
	text-align: right !important;
  }
  .text-md-center {
	text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
	text-align: left !important;
  }
  .text-lg-right {
	text-align: right !important;
  }
  .text-lg-center {
	text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
	text-align: left !important;
  }
  .text-xl-right {
	text-align: right !important;
  }
  .text-xl-center {
	text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-bold {
  font-weight: 700;
}

.font-italic {
  font-style: italic;
}

.text-white {
  color: #fff !important;
}

.text-muted {
  color: #7a7a7a !important;
}

a.text-muted:focus,
a.text-muted:hover {
  color: #616161 !important;
}


.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.invisible {
  visibility: hidden !important;
}


body,
html {
  height: 100%;
}

body {
  direction: ltr;
  font-family: $font-family-sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

ul {
  list-style: none;
}

#search_filters ul {
  padding: 0;
}

.color,
.custom-checkbox input[type="checkbox"] + span.color {
  width: 2.2rem;
  height: 2.2rem;
  display: inline-block;
  margin: 0.3125rem;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  background-size: contain;
}

.color.active,
.color:hover,
.custom-checkbox input[type="checkbox"] + span.color.active,
.custom-checkbox input[type="checkbox"] + span.color:hover {
  border: 2px solid #232323;
}


.label,
label {
  text-align: right;
  font-size: 0.875rem;
}

small.label,
small.value {
  font-size: 0.8125rem;
}

.form-control-label {
  padding-top: 0.625rem;
}

.form-control {
  color: #7a7a7a;
  padding: 0.5rem 1rem;
}

.form-control:focus {
  background-color: #fff;
  color: #232323;
}

.form-control:focus,
.input-group.focus {
  outline: 0.1875rem solid $primary;
}

.input-group .form-control:focus {
  outline: none;
}

.input-group .input-group-btn {
  height: 100%;
}

.input-group .input-group-btn > .btn {
  border: 0;
  box-shadow: none;
  color: #fff;
  font-size: 0.6875rem;
  font-weight: 500;
  margin-left: 0;
  padding: 0.78rem 1rem;
}

.input-group .input-group-btn > .btn[data-action="show-password"] {
  background: #7a7a7a;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #7a7a7a;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #7a7a7a;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #7a7a7a;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #7a7a7a;
}

input:active::-webkit-input-placeholder,
textarea:active::-webkit-input-placeholder {
  color: #232323;
}

input:active::-moz-placeholder,
textarea:active::-moz-placeholder {
  color: #232323;
}

input:active:-ms-input-placeholder,
textarea:active:-ms-input-placeholder {
  color: #232323;
}

input:active:-moz-placeholder,
textarea:active:-moz-placeholder {
  color: #232323;
}

.form-control-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #ffffff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAPklEQVR4Ae3TwREAEBQD0V/6do4SXPZg7EsBhsQ8IEmSMOsiuEfg3gL3oXC7wK0bd1G4o8X9F4yIkyQfSrIByQBjp7QuND8AAAAASUVORK5CYII=") no-repeat scroll right 0.5rem center/1.25rem 1.25rem;
}

.form-control-valign {
  padding-top: 0.5rem;
}


.form-group.has-error input,
.form-group.has-error select {
  outline: 0.1875rem solid #ff4c4c;
}

.form-group.has-error .help-block {
  color: #ff4c4c;
}

.group-span-filestyle label {
  margin: 0;
}


.custom-radio {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #7a7a7a;
  background: #fff;
  margin-right: 1.25rem;
}

.custom-radio input[type="radio"] {
  opacity: 0;
  cursor: pointer;
}

.custom-radio input[type="radio"]:checked + span {
  display: block;
  background-color: $primary;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: 0.125rem;
  top: 0.125rem;
}

.custom-radio input[type="radio"]:focus + span {
  border-color: #7a7a7a;
}

.custom-checkbox {
  position: relative;
}

.custom-checkbox input[type="checkbox"] {
  margin-top: 0.25rem;
  opacity: 0;
  cursor: pointer;
  position: absolute;
}

.custom-checkbox input[type="checkbox"] + span {
  margin-right: 3px;
  display: inline-block;
  width: 0.9375rem;
  height: 0.9375rem;
  vertical-align: middle;
  cursor: pointer;
  border: 2px solid #232323;
}

.custom-checkbox input[type="checkbox"] + span .checkbox-checked {
  display: none;
  margin: -0.25rem -0.125rem;
  font-size: 1.1rem;
  color: #232323;
  position: relative;

  &:before {
    font-size: 0.55rem;
    padding-left: 2px;
    padding-bottom: 3px;
    margin: 0;
    margin-top: 5px;
    top: 0;
    position: absolute;
  }

}

.custom-checkbox input[type="checkbox"]:checked + span .checkbox-checked {
  display: block;
}

.custom-checkbox input[type="checkbox"]:focus + span {
  border-color: #7a7a7a;
}

.custom-checkbox label {
  text-align: left;
  width: 100%;
}

.text-muted {
  font-size: 0.875rem;
}

.done {
  color: #4cbb6c;
  display: inline-block;
  padding: 0 0.8125rem;
}

.thumb-mask > .mask {
  position: relative;
  width: 3.438rem;
  height: 3.438rem;
  overflow: hidden;
  margin: 0.625rem 0;
}

.thumb-mask > .mask img {
  width: 55px;
  height: 55px;
}

.help-block {
  margin-top: 0.625rem;
}

.btn.disabled,
.btn.disabled:hover {
  background: #7a7a7a;
}

.alert-warning .alert-text {
  font-size: 0.9375rem;
  padding-top: 0.625rem;
}

.alert-warning .alert-link {
  border-radius: 2px;
  border-width: 2px;
  margin-left: 0.625rem;
  padding: 0.3125rem 1.25rem;
  font-weight: 700;
  font-size: 0.8125rem;
  color: #6c868e;
}

.alert-warning ul li:last-child .alert-link {
  color: #fff;
}

.alert-warning .warning-buttons {
  margin-top: 0.3125rem;
}

.alert {
  font-size: 0.8125rem;
}

.nav-item .nav-link,
.nav-item .nav-separtor {
  color: #7a7a7a;
  font-weight: 700;
}

.nav-item .nav-link.active,
.nav-item .nav-separtor.active {
  color: #232323;
}

.separator {
  margin: 0;
}

.ps-alert-error {
  margin-bottom: 0;
}

.ps-alert-error .item,
.ps-alert-success .item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #ff4c4c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #ff4c4c;
  margin-bottom: 1rem;
}

.ps-alert-error .item i,
.ps-alert-success .item i {
  border: 15px solid #ff4c4c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ps-alert-error .item i svg,
.ps-alert-success .item i svg {
  background-color: #ff4c4c;
  width: 24px;
  height: 24px;
}

.ps-alert-error .item p,
.ps-alert-success .item p {
  background-color: #fff;
  margin: 0;
  padding: 18px 20px;
  width: 100%;
}

.ps-alert-success {
  padding: 0.25rem 0.25rem 2.75rem;
}

.ps-alert-success .item {
  border-color: #4cbb6c;
  background-color: #4cbb6c;
}

.ps-alert-success .item i {
  border-color: #4cbb6c;
}

.ps-alert-success .item i svg {
  background-color: #4cbb6c;
}

#mobile_top_menu_wrapper {
  padding: 15px;
  background: #fff;
}

#mobile_top_menu_wrapper #top-menu {
  margin-bottom: 0.625rem;
}


#mobile_top_menu_wrapper .js-top-menu-bottom a {
  color: #7a7a7a;
}

#mobile_top_menu_wrapper .js-top-menu-bottom .user-info {
  padding: 0 0.625rem;
}

#mobile_top_menu_wrapper .js-top-menu-bottom .user-info a {
  padding: 0.625rem 0;
  display: block;
  width: 100%;
}


.condition-label {
  margin-left: 1rem;
  margin-top: 0;
}

.condition-label label {
  text-align: inherit;
}

.cancel-address {
  margin: 0.625rem;
  display: block;
  color: #7a7a7a;
  text-decoration: underline;
}

#order-summary-content {
  padding-top: 0.9375rem;
}

#order-summary-content h4.h4 {
  margin-top: 0.625rem;
  margin-bottom: 1.25rem;
  color: #232323;
}

#order-summary-content h4.black {
  color: #000;
}

#order-summary-content h4.addresshead {
  margin-top: 0.1875rem;
}

#order-summary-content .noshadow {
  box-shadow: none;
}

#order-summary-content #order-items {
  border-right: 0;
}

#order-summary-content #order-items h3.h3 {
  color: #232323;
  margin-top: 1.25rem;
}

#order-summary-content #order-items table tr:first-child td {
  border-top: 0;
}

#order-summary-content .order-confirmation-table {
  padding: 1rem;
  margin-bottom: 2rem;
  background-color: #fff;
  border: 3px solid #e5e5e5;
  border-radius: 0;
}

#order-summary-content .summary-selected-carrier {
  margin-bottom: 0.75rem;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  padding: 1rem;
}

#order-summary-content .step-edit {
  display: inline;
  color: #7a7a7a;
}

#order-summary-content .step-edit:hover {
  cursor: pointer;
}

#order-summary-content a .step-edit {
  color: #7a7a7a;
}

#delivery,
#gift_message {
  max-width: 100%;
  border-color: #232323;
}

#delivery textarea,
#gift_message textarea {
  max-width: 100%;
  margin-bottom: 10px;
}

#order-details > .card-title {
  margin-bottom: 1.875rem;
}

#order-details ul {
  margin-bottom: 1.25rem;
}

#order-details ul li {
  margin-bottom: 0.625rem;
}

#order-items table {
  width: 100%;
}

#order-items table tr {
  height: 1.875rem;
}

#order-items table tr td:last-child {
  text-align: right;
}

#order-items .order-line {
  margin-top: 1rem;
}

#order-items .image img {
  width: 100%;
  border: 1px solid gray-lighter;
  margin-bottom: 1rem;
}

#order-items .details {
  margin-bottom: 1rem;
}

#order-items .details .customizations {
  margin-top: 0.625rem;
}

#order-items .qty {
  margin-bottom: 1rem;
}

#order-confirmation #registration-form {
  width: 50%;
  margin: 0 auto 1rem;
}

@media (max-width: 991px) {
  .done {
	margin: 0;
	padding: 0;
  }
  #checkout .checkout-step .address-item {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
  }
  #checkout .checkout-step .delivery-option-2 {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
  }
  .delivery-option {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: auto;
  }
  .delivery-option .custom-radio {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
  }
  .condition-label label[for="conditions_to_approve[terms-and-conditions]"] {
	text-align: left;
  }
  #order-confirmation #registration-form {
	width: 100%;
  }
}

@media (max-width: 767px) {
  #checkout section.checkout-step.-reachable.-complete h1 .step-edit {
	float: none;
	margin-top: 0.25rem;
	margin-left: 1.25rem;
  }
  #checkout section.checkout-step .content {
	padding: 0.9375rem;
  }
  #checkout .form-group {
	margin-bottom: 0.5rem;
  }
  #order-items {
	border-right: 0;
	margin-bottom: 2.5rem;
  }
  #order-items .card-title {
	border-bottom: 1px solid #f1f1f1;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
  }
  #order-items hr {
	border-top-color: #f1f1f1;
  }
  .bold {
	font-weight: 700;
  }
  #order-details {
	padding-left: 0.9375rem;
  }
  #order-details .card-title {
	border-bottom: 1px solid #f1f1f1;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
  }
}

@media (max-width: 575px) {

  #payment-confirmation button {
	font-size: 0.875rem;
  }
  #payment-confirmation button.btn {
	white-space: normal;
  }
}

.js-payment-binary,
.js-payment-binary .accept-cgv {
  display: none;
}

.js-payment-binary.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.js-payment-binary.disabled:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.js-payment-binary.disabled .accept-cgv {
  display: block;
}

.table-labeled td,
.table-labeled th {
  vertical-align: middle;
}

.table-labeled .label {
  font-weight: 400;
  border-radius: 3px;
  font-size: inherit;
  padding: 0.25rem 0.375rem;
  margin: 0.125rem;
  color: #fff;
  white-space: nowrap;
}

.page-order .table {
  margin-bottom: 0;
}

.page-order table td,
.page-order table th {
  padding: 0.5rem;
}

.page-order table thead th {
  text-align: center;
}

#authentication .custom-checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#authentication .custom-checkbox span {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 0.9375rem;
  flex: 0 0 0.9375rem;
}

.form-group .radio-inline {
  margin-right: 10px;
}

.page-customer-account #content .forgot-password {
  text-align: center;
  font-size: 0.875rem;
  margin-top: 1rem;
  padding-bottom: 0.9375rem;
}

.page-customer-account #content .no-account {
  text-align: center;
  font-size: 1rem;
}

.page-addresses .address {
  font-size: 0.875rem;
}

.page-addresses .address .address-body h4 {
  font-size: 1rem;
  font-weight: 700;
}

.page-order-detail {
  font-size: 0.875rem;
}

.page-order-detail .box {
  background: #fff;
  border: 1px solid #eeeeee;
  padding: 1rem;
  margin-bottom: 1rem;
}

.page-order-detail h3 {
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #232323;
  margin-bottom: 1rem;
}

.page-order-detail #order-infos ul {
  margin: 0;
}

.page-order-detail #order-history .history-lines .history-line {
  padding: 0.5rem 0;
  border-bottom: 1px solid #f6f6f6;
}

.page-order-detail #order-history .history-lines .history-line:last-child {
  border-bottom: 0;
}

.page-order-detail #order-history .history-lines .history-line .label {
  display: inline-block;
  margin: 0.25rem 0;
  padding: 0.25rem 0.375rem;
  color: #fff;
  border-radius: 3px;
}

.page-order-detail .addresses h4 {
  font-size: 1rem;
  font-weight: 700;
}

.page-order-detail #order-products.return {
  margin-bottom: 1rem;
}

.page-order-detail #order-products.return th.head-checkbox {
  width: 30px;
}

.page-order-detail #order-products.return td {
  padding: 1.375rem 0.75rem;
}

.page-order-detail #order-products.return td.qty {
  min-width: 125px;
}

.page-order-detail #order-products.return td.qty .current {
  width: 30%;
  float: left;
  text-align: right;
  padding-right: 0.5rem;
}

.page-order-detail #order-products.return td.qty .select {
  width: 70%;
  float: left;
  margin: -0.625rem 0;
  padding-left: 0.25rem;
}

.page-order-detail #order-products.return td.qty .select select {
  text-align: center;
}

.page-order-detail .order-items {
  padding: 0 !important;
}

.page-order-detail .order-items .order-item {
  padding: 1rem 1rem 0;
  border-bottom: 1px solid #f6f6f6;
}

.page-order-detail .order-items .order-item .checkbox {
  width: 30px;
  float: left;
  padding: 0 0.9375rem;
}

.page-order-detail .order-items .order-item .content {
  width: calc(100% - 30px);
  float: left;
  padding: 0 0.9375rem;
}

.page-order-detail .order-items .order-item .desc {
  margin-bottom: 1rem;
}

.page-order-detail .order-items .order-item .desc .name {
  font-weight: 700;
}

.page-order-detail .order-items .order-item .qty {
  margin-bottom: 1rem;
}

.page-order-detail .order-items .order-item .qty .q,
.page-order-detail .order-items .order-item .qty .s {
  margin-bottom: 0.25rem;
}

.page-order-detail .messages .message {
  margin-top: 0.5rem;
  border-bottom: 1px solid #f6f6f6;
}

.page-order-detail .messages .message:last-child {
  border-bottom: 0;
}

.page-order-detail .messages .message > div {
  margin-bottom: 0.5rem;
}

.page-order-detail .customization {
  margin-top: 0.75rem;
}

#order-return-infos .thead-default th {
  color: #232323;
}

#order-return-infos .customization {
  margin-top: 0.75rem;
}


.page-my-account #content .links a span.link-item {
  box-shadow: none;
  border-radius: 12px;
  background: lighten(#efefef, 4%);
  font-size: 15px;
  padding: 1rem;
  display: block;
  height: 100%;
  @include transition($transition-base);

  &:hover {
	@include transition($transition-base);
	background: #6bcc4f;
	color: #ffffff;
  }

}


.page-my-account #content .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;

  a {
	//text-align: center;
	display: inline-block;
	font-size: 1.2rem;
	font-weight: $font-weight-medium;
	color: #000000;
	padding: 0 0.9375rem;
	margin-bottom: 1rem;

	&:hover {
	  color: lighten(#6bcc4f, 20%);
	}

	i {
	  display: none;
	  font-size: 2.6rem;
	  width: 100%;
	  color: #000000;
	  padding-bottom: 3.4rem;
	}

  }

}


#history .orders .order {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #f6f6f6;
}

#history .orders .order a h3 {
  color: #7a7a7a;
}

#history .orders .order .label {
  display: inline-block;
  margin: 0.25rem 0;
  padding: 0.25rem 0.375rem;
  color: #fff;
  border-radius: 3px;
}

#history .orders .order:last-child {
  border-bottom: 0;
}


.page-footer {
  display: flex;

  width: 100%;

  //padding-top: 3rem;
  //padding-bottom: 3rem;
  //padding-right: ($grid-gutter-width / 2);
  //padding-left: ($grid-gutter-width / 2);

  @include media-breakpoint-down(sm) {
	flex-flow: column;
	width: auto;
  }

  a {


	@include media-breakpoint-down(sm) {
	  width: 100%;
	  margin-bottom: 20px;
	}

  }

}

.forgotten-password {
  padding: 4px;
}

.forgotten-password .form-fields .center-email-fields {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .forgotten-password .form-fields .center-email-fields {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
  }
  .forgotten-password .form-fields .center-email-fields button {
	margin: 10px;
	width: calc(100% - 20px);
  }
}

.forgotten-password .form-fields .center-email-fields button {
  height: 38px;
}

.forgotten-password .form-fields .email {
  padding-left: 0;
  padding-right: 0;
  width: 430px;
}

@media (max-width: 767px) {
  .forgotten-password .form-fields .email {
	padding-left: 10px;
	padding-right: 10px;
	width: 100%;
  }
}

.forgotten-password .form-fields .email input {
  height: 38px;
}

.forgotten-password .form-fields label.required {
  width: 130px;
}

.send-renew-password-link {
  padding-left: 10px;
  padding-right: 10px;
}

.renew-password .email {
  padding-bottom: 30px;
}


.product-thumbnail {
  display: block;
}


.highlighted-informations {
  bottom: 5.5rem;
}

.highlighted-informations:after {
  opacity: 1;
}

.highlighted-informations.no-variants {
  bottom: 4.2rem;
}

.products-section-title {
  text-align: center;
  margin-bottom: 1.5rem;
}

.variant-links {
  position: relative;
  text-align: center;
  width: 100%;
  top: -0.25em;
  padding-top: 0.1875rem;
  min-height: 2.5rem;
  background: #fff;
}

.highlighted-informations {
  position: absolute;
  bottom: 1.25rem;
  padding-top: 0.625rem;
  z-index: 0;
  background: #fff;
  text-align: center;
  width: 257px;
  height: 3.125rem;
  box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.2);
  transition: bottom 0.3s;
}

.quick-view {
  color: #7a7a7a;
  font-size: 1rem;
}

.quick-view:hover {
  color: $primary;
}


#custom-text {
  background: #fff;
  border-radius: 2px;
  margin-bottom: 1.5rem;
  padding: 3.125rem;
  text-align: center;
}

#custom-text h2 {
  text-transform: uppercase;
  color: #232323;
  font-size: 1.563rem;
  font-weight: 700;
}

#custom-text p {
  color: #232323;
  font-weight: 400;
  font-size: 1.1em;
}

#custom-text p .dark {
  color: #7a7a7a;
  font-weight: 400;
}

@media (max-width: 991px) {
  #block-cmsinfo {
	padding: 1.25rem 1.875rem;
  }
}

#products {
  color: #7a7a7a;
}

#products .products-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#products .up {
  margin-bottom: 1rem;
}

#products .up .btn-secondary,
#products .up .btn-tertiary {
  color: #7a7a7a;
  text-transform: inherit;
}


.pagination {
  width: 100%;
}

.pagination .page-list li {
  display: inline;
}

.pagination .previous {
  padding-top: 6px;
  float: left;
}

.pagination .next {
  padding-top: 6px;
  float: right;
}


@media (max-width: 767px) {
  #category #left-column {
	width: 100%;
  }


  #category #content-wrapper {
	width: 100%;
  }
  .products-selection h1 {
	padding-top: 0;
	text-align: center;
	margin-bottom: 1rem;
  }
  .products-selection .showing {
	padding-top: 1rem;
  }
  #best-sales #content-wrapper,
  #new-products #content-wrapper,
  #prices-drop #content-wrapper {
	width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .card {
	box-shadow: none;
	background-color: inherit;
	border: none;
  }
}

#product #content {
  position: relative;
  max-width: 452px;
  margin: 0 auto;
}


.input-color {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 1.25rem;
  width: 1.25rem;
}

.input-container {
  position: relative;
}

.input-radio {
  position: absolute;
  top: 0;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.input-color:checked + span,
.input-color:hover + span,
.input-radio:checked + span,
.input-radio:hover + span {
  border: 2px solid #232323;
}

.radio-label {
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  display: inline-block;
  padding: 0.125rem 0.625rem;
  font-weight: 700;
  border: 2px solid #fff;
}


.product-prices {
  display: flex;
  flex-flow: column;
  position: relative;
}


.product-prices .tax-shipping-delivery-label {
  font-size: 0.8125rem;
  color: #7a7a7a;
}

.product-prices .tax-shipping-delivery-label .delivery-information {
  padding: 0 0 0 2px;
}

.product-prices .tax-shipping-delivery-label .delivery-information:before {
  content: "-";
  padding: 0 2px 0 0;
}


.product-discount {
  color: #7a7a7a;

  .regular-price {
	font-weight: $font-weight-medium;
	font-size: 16px;
	text-decoration: line-through;
  }

}


.product-cover .layer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  background: hsla(0, 0%, 100%, 0.7);
  text-align: center;
  cursor: pointer;
}

.product-cover .layer:hover {
  opacity: 1;
}

.product-cover .layer .zoom-in {
  font-size: 6.25rem;
  color: #7a7a7a;
}


.product-images > li.thumb-container {
  display: inline;
}

.product-images > li.thumb-container > .thumb {
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin-bottom: 0.625rem;
}

.images-container .product-images > li.thumb-container > .thumb {
  margin-right: 0.8125rem;
}

.product-images > li.thumb-container > .thumb.selected,
.product-images > li.thumb-container > .thumb:hover {
  border: 3px solid $primary;
}

#main .images-container .js-qv-mask {
  white-space: nowrap;
  overflow: hidden;
}

#main .images-container .js-qv-mask.scroll {
  width: calc(100% - 60px);
  margin: 0 auto;
}

.scroll-box-arrows {
  display: none;
}

.scroll-box-arrows.scroll {
  display: block;
}

.scroll-box-arrows i {
  position: absolute;
  bottom: 1.625rem;
  height: 100px;
  line-height: 100px;
  cursor: pointer;
}

.scroll-box-arrows .left {
  left: 0;
}

.scroll-box-arrows .right {
  right: 0;
}

#product-availability {
  margin-top: 0.625rem;
  line-height: 1.4;
  display: inline-block;
  font-weight: 400;
}

#product-availability .material-icons {
  line-height: inherit;
}

#product-availability .product-available {
  color: #4cbb6c;
}

#product-availability .product-last-items,
#product-availability .product-unavailable {
  color: #ff9a52;
}

#product-details .label {
  font-size: 1rem;
  color: #232323;
  font-weight: 700;
}


.product-refresh {
  margin-top: 1.25rem;
}


.products-selection {
  margin-bottom: 1.25rem;
}

.products-selection .title {
  color: #7a7a7a;
}

@media (max-width: 991px) {
  .product-cover img {
	width: 100%;
  }
  #product-modal .modal-content .modal-body {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-left: 0;
  }
  #product-modal .modal-content .modal-body img.product-cover-modal {
	width: 100%;
  }
  #product-modal .modal-content .modal-body .arrows {
	display: none;
  }
  #product-modal .modal-content .modal-body .image-caption {
	width: 100%;
  }
  .modal .modal-body {
	padding: 1.875rem;
  }
}

@media (max-width: 767px) {
  .modal .divide-right {
	border-right: none;
  }
  .modal .modal-body {
	padding: 1rem;
  }
}

.cart-grid {
  margin-bottom: 1rem;
}

.cart-items {
  margin-bottom: 0;
}

.cart-item {
  padding: 1rem 0;
}


.cart-grid-body {
  margin-bottom: 0.75rem;

  .card {
	border: 0 !important;
  }

}

.cart-grid-body a.label:hover {
  color: $primary;
}

.cart-grid-body .card-block {
  padding: 1rem;
}

.cart-grid-body .card-block h1 {
  margin-bottom: 0;
}

.cart-grid-body .cart-overview {
  padding: 1rem;
}

.cart-grid-right .promo-discounts {
  margin-bottom: 0;
}

.cart-grid-right .promo-discounts .cart-summary-line .label {
  color: #7a7a7a;
}

.cart-grid-right .promo-discounts .cart-summary-line .label .code {
  text-decoration: underline;
  cursor: pointer;
}

.product-line-grid-left img {
  max-width: 100%;
  @include media-breakpoint-down(md) {
	margin-top: 1rem;
	margin-bottom: 2rem;
  }

}

.product-line-grid-body > .product-line-info > .label {
  padding: 0;
  line-height: inherit;
  text-align: left;
  white-space: inherit;
}

.product-line-grid-body > .product-line-info > .out-of-stock {
  color: red;
}

.product-line-grid-body > .product-line-info > .available {
  color: #4cbb6c;
}

.product-line-grid-body > .product-line-info > .unit-price-cart {
  padding-left: 0.3125rem;
  font-size: 0.875rem;
  color: #7a7a7a;
}

.product-line-grid-right .cart-line-product-actions,
.product-line-grid-right .product-price {
  color: #232323;
  font-size: 16px;
  line-height: 36px;
}

.product-line-grid-right .cart-line-product-actions .remove-from-cart,
.product-line-grid-right .product-price .remove-from-cart {
  color: #232323;
  display: inline-block;
  margin-top: 0.3125rem;
}

@media (max-width: 767px) {
  .product-line-grid-body {
	margin-bottom: 1rem;
  }
}

@media (max-width: 575px) {
  .cart-items {
	padding: 1rem 0;
  }
  .cart-item {
	border-bottom: 1px solid #f1f1f1;
  }
  .cart-item:last-child {
	border-bottom: 0;
  }
  .cart-grid-body .cart-overview {
	padding: 0;
  }
  .cart-grid-body .no-items {
	padding: 1rem;
	display: inline-block;
  }
  .product-line-grid-left {
	padding-right: 0 !important;
  }
}

@media (max-width: 360px) {
  .product-line-grid-right .price,
  .product-line-grid-right .qty {
	width: 100%;
  }
}


.quickview .modal-dialog {
  width: calc(100% - 30px);
  max-width: 64rem;
}

.quickview .modal-content {
  min-height: 28.13rem;
}

.quickview .modal-body {
  min-height: 23.75rem;
}

.quickview .modal-footer {
  border-top: 1px solid hsla(0, 0%, 48%, 0.3);
}

.quickview .layer {
  display: none;
}

.quickview .product-cover img {
  width: 95%;
}

.quickview .images-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  min-height: 21.88rem;
}

.quickview .images-container .product-images > li.thumb-container > .thumb {
  width: 100%;
  max-width: 4.938rem;
  margin-bottom: 0.8125rem;
  background: #fff;
}

.quickview .mask {
  width: 35%;
  max-height: 22.5rem;
  overflow: hidden;
  margin-left: 0.625rem;
}

.quickview .arrows {
  position: absolute;
  top: 0;
  bottom: 0;
  max-height: 22.5rem;
  right: 5rem;
  z-index: 0;
}

.quickview .arrows .arrow-up {
  margin-top: -3.125rem;
  cursor: pointer;
  opacity: 0.2;
}

.quickview .arrows .arrow-down {
  position: absolute;
  bottom: -1.875rem;
  cursor: pointer;
}

.quickview .social-sharing {
  margin-top: 0;
}


.linklist .blockcms-title a {
  color: #232323;
}

.account-list a {
  color: #7a7a7a;
}

.account-list a:hover {
  color: $primary;
}

.block-contact-title,
.blockcms-title,
.myaccount-title,
.myaccount-title a {
  font-weight: 700;
  font-size: 1rem;
}


.links .collapse {
  display: inherit;
}

@media (max-width: 767px) {

  .links .collapse {
	display: none;
  }
  .links .collapse.in {
	display: block;
  }
  .links .title {
	padding: 0.625rem;
	border-bottom: 1px solid #f6f6f6;
	cursor: pointer;
  }
  .links .title .collapse-icons .remove,
  .links .title[aria-expanded="true"] .collapse-icons .add {
	display: none;
  }
  .links .title[aria-expanded="true"] .collapse-icons .remove {
	display: block;
  }
  .links .navbar-toggler {
	display: inline-block;
	padding: 0;
  }
}


.contact-form {
  padding: 1rem;
  width: 100%;
}

#pagenotfound #main .page-content,
#products #main .page-content {
  margin-bottom: 10rem;
}


.customization-modal .modal-content {
  border-radius: 0;
  border: 1px solid #f6f6f6;
}

.customization-modal .modal-content .modal-body {
  padding-top: 0;
}

.customization-modal .modal-content .modal-body .product-customization-line {
  padding-bottom: 0.9375rem;
  padding-top: 0.9375rem;
  border-bottom: 1px solid #f6f6f6;
}

.customization-modal .modal-content .modal-body .product-customization-line .label {
  font-weight: 700;
  text-align: right;
}

.customization-modal .modal-content .modal-body .product-customization-line:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}


#notifications ul {
  margin-bottom: 0;
}


@media (max-width: 991px) {

  .sub-menu {
	left: 0;
	min-width: 100%;
  }
  .modal .product-image {
	width: 100%;
	display: block;
	max-width: 15.63rem;
	margin: 0 auto 0.9375rem;
  }
  .modal .cart-content {
	padding-left: 0;
  }
  .modal .product-name,
  #product-availability {
	margin-top: 0.625rem;
  }
  #search_filters .facet .facet-label {
	text-align: left;
  }
}

@media (max-width: 767px) {
  #wrapper {
	box-shadow: none;
  }
  #checkout-cart-summary {
	float: none;
	width: 100%;
	margin-top: 1rem;
  }

  section.checkout-step {
	width: 100%;
  }
  .default-input {
	min-width: 100%;
  }
  label {
	clear: both;
  }

  .block-contact {
	padding-left: 0.9375rem;
	border: none;
  }
  .dropdown-item,
  .menu {
	padding-left: 0;
  }
}


#wrapper {
  position: relative;
  overflow: hidden;

  .page-product {
	//padding-top: 132px;
  }

}

.page-product {

  .breadcrumb_container {
    padding-right: 22px !important;
    padding-left: 22px !important;
  }
  //padding-top: 132px;
}



.customer_privacy {
  display: none;
}