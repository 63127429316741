.brand-slider {



  &__item {
	padding: 10px;
	background: #FFFFFF;
	border: 1px solid #E4E4E4;
	@include border-radius(15px);
  }

}