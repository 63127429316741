.b-recipes {


  background: linear-gradient(0deg, rgba(249,249,249,1) 0%, rgba(249,249,249,1) 60%, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 100%);


  &__item {
	padding-bottom: 30px;

  }

  &__section-title {
	font-weight: $font-weight-bold;
  }

  &__name {
	font-size: 30px;
	font-weight: $font-weight-bold;
  }

  &__label {
	font-weight: $font-weight-bold;
	font-size: 16px;
	color: #5B675B;
	margin-bottom: 4px;
  }

  &__value {
	font-size: 16px;
	color: #F27E1C;
	font-weight: $font-weight-medium;
  }

  &__img {
	@include border-radius(15px);
  }

  &__item-content {
	padding: 20px;
	background: #ffffff;
	width: 100%;
	@include border-radius(15px);


	@include media-breakpoint-up(lg) {
	  width: 80%;
	  left:0;
	  bottom: 0;
	  z-index: 10;
	  padding: 30px;
	  position: absolute;
	}

  }

  .btn {
	padding: 10px 25px;
  }

}



.b-recipe__ingredients {
  background: #F27E1C;
  color: #ffffff;
  padding: 36px;
  border-radius: 15px;

  ul {
	list-style: disc;
	text-transform: uppercase;
	padding-left: 20px;
	font-weight: 600;
  }

  a {
	color: #ffffff;
  }

  h3 {
	text-transform: uppercase;
	color: #ffffff;
	margin-bottom: 16px;
  }

}

.recipe__wrap-title {
  background: #ffffff;

  @include media-breakpoint-up(md) {
	padding: 60px;
	margin-left: -100px;
	width: calc(100% + 100px);
  }

}

.recipe__content {

  ol {
	counter-reset: item;
	list-style-type: none;
	line-height: 1.8;
	margin-left: -40px;
	margin-top: 40px;
	margin-bottom: 40px;
  }

  ol li {
	display: block;
	position: relative;
	padding-bottom: 2rem;
  }

  ol li span {
	margin-left: 60px;
	display: block;
  }

  ol li:before {
	content: counter(item) " ";
	counter-increment: item;
	color: #ffffff;
	position: absolute;
	top: 20px;
	margin-top: -1em;
	background: #5bbd3f;
	height: 2.5em;
	width: 2.5em;
	line-height: 2.5em;
	text-align: center;
	border-radius: 2.5em;
	font-weight: 600;
  }


}