#contact {


  #gdpr_consent {
	label {
	  font-size: 12px;
	  line-height: 20px;
	}
  }

}


.b-social-follow--content {


  .d-flex {

	@include media-breakpoint-up(md) {
	  justify-content: flex-start !important;
	}

  }

  li {
	margin:0;
	padding-right: 12px;

	&:last-child {
	  padding-right:0;
	}

  }

  img {
	height: 30px;
	width: 30px;
	//filter: invert(77%) sepia(51%) saturate(551%) hue-rotate(53deg) brightness(86%) contrast(90%);
  }

}