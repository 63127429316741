.cart-icon {

  border-radius: 50%;
  background: darken(#f9f9f9,5%);
  padding: 2rem;
  margin: 1rem 0 1rem;

  i {
    opacity: 0.6;
	font-size: 3rem;
  }
}

#cart-subtotal-shipping {
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;

  .value {
    color: rgba(11,180,7,1) !important;
    font-weight: 700;
  }

}