.breadcrumb {
  list-style: none;
  border-radius: 0;
  margin-bottom:0;

  ol {
	display: flex;
  }

  li {

	&::before {
	  display: inline-block; // Suppress underlining of the separator in modern browsers
	  padding: 0 0.5rem;
	  color: #000000;
	  content: "/";
	  vertical-align: middle;
	}

	&:nth-child(1)::before {
	  content: "";
	  padding: 0;
	  float: left;
	}
  }

}