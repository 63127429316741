//$font-icon:" Font Awesome 5 Free";
$main_border: 1px solid #e5e5e5;
//@import "animate";
@mixin pos-transition($number) {
  -webkit-transition: all $number linear;
  -moz-transition: all $number linear;
  -ms-transition: all $number linear;
  -o-transition: all $number linear;
  transition: all $number linear;
}

@mixin pos-transform($number) {
  -moz-transform: scale3d($number, $number, $number);
  -webkit-transform: scale3d($number, $number, $number);
  -o-transform: scale3d($number, $number, $number);
  -ms-transform: scale3d($number, $number, $number);
  transform: scale3d($number, $number, $number);
}


@mixin placeholder {
  &::-moz-placeholder {
	@content
  }
  &::-webkit-input-placeholder {
	@content
  }
  &:-ms-input-placeholder {
	@content
  }
}

@mixin placeholder-style {
  color: #ffffff;
}


/* edit from here */
.ui-menu .ui-menu-item a.ui-state-focus, .ui-menu .ui-menu-item a {
  font-size: 12px;
}

.ui-menu .ui-menu-item a.ui-state-focus, .ui-menu .ui-menu-item a.ui-state-active {
  margin: 0;
  background: none;
  border: 0;
  cursor: pointer;
}

button {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

@media (min-width: 1920px) {
  .modal {
	top: 20%;
	bottom: auto;
  }
  .modal-dialog {
	margin: 0 auto;
  }
}

/* edit theme */


/* custom page */
.card {
  border: none;
  padding: 0;
}


.contact-form {
  padding: 0;
}

.form-control {
  border: $main_border;
  font-size: 14px;
}

.form-control:focus, .input-group.focus {
  outline: 0;
}

.input-group .input-group-btn > .btn[data-action=show-password] {
  &:hover {
  }
}

.form-control-label {
  font-size: 13px;
  text-align: left;
}

.input-group .input-group-btn > .btn {
  padding: .67rem 1rem;
}






#order-slip,
#order-detail,
#addresses,
#my-account,
#discount,
#order-confirmation,
#history,
#order-follow {

  @include media-breakpoint-up(sm) {

	.page-content {
	  display: table;
	  width: 100%;
	  padding: 10px 10px 0;
	}

	.page-footer {
	  padding: 0 10px 40px;
	}


  }

  #main {
	width: 100%;

	@include media-breakpoint-up(sm) {
	  width: 80%;
	}

	@include media-breakpoint-up(md) {
	  width: 70%;
	  margin: 0 auto;
	}

	margin-bottom: 4rem !important;

  }


}

#my-account .page-footer {
  padding: 0;
}



#identity,
#address,
#password,
#module-psgdpr-gdpr {

  @include media-breakpoint-up(sm) {

	.form-fields {
	  margin-bottom: 3rem;
	}

	.page-content {
	  display: table;
	  width: 100%;
	  padding: 30px 10px 0;
	}

	.page-footer {
	  padding: 0 10px 40px;

	  a {
		width: 100%;
		text-align: center;
	  }
	}
  }

  #main {
	width: 100%;
	margin: 0 auto;
	margin-bottom: 4rem;

	@include media-breakpoint-up(sm) {
	  width: 60%;
	}

	@include media-breakpoint-up(md) {
	  width: 45%;
	}
  }

}

.page-customer-account #content {
  font-size: 14px;
}

.page-customer-account #content .no-account {
  text-align: center;
  font-size: 14px;
}

.custom-checkbox input[type=checkbox] + span {
  margin-top: 0;
}


.separator {
  border-top: $main_border;
}

.cart-grid-body .cart-overview {
  padding: 1rem 0;
}


.product-line-info a.product_name {
  display: block;
  overflow: hidden;
  margin-bottom: 10px;
  float: left;
  width: 100%;
}

.product-line-info .regular-price {
  margin: 0 5px;
}

.cart-summary-line .value {
  font-size: 16px;
}

.cart-summary-products p {

}

.cart-grid-body .card-block {
  padding: 0;
}

.cart-item {
  border-bottom: $main_border;

  .product_name {
	font-weight: $font-weight-medium;
	font-size: 16px;
  }

  &:last-child {
	border-bottom: 0;
  }

}


#checkout section.checkout-step {
  border: $main_border;
  margin-bottom: 10px;
}


#checkout .custom-checkbox span {
  margin-top: 0;
}

#checkout .checkout-step .address-item.selected {
  border: 2px solid #ebebeb;
}

#checkout .checkout-step .address-alias {
  vertical-align: -2px;
  font-size: 14px;
  margin-left: 5px;
}

#checkout .checkout-step .address {
  line-height: 24px;
}

.checkout-inner {
  border: $main_border;
}



main {
  overflow: hidden;
}


#_mobile_cart_block {
  .blockcart {
	margin-top: 0;

	.button_cart {
	  a.mobile {
		.item_count {
		  top: 5px;
		  left: 18px;
		}
	  }
	}
  }
}


/* end style layer cart */


.desc_title {
  color: #69696c;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
}

.product-price-and-shipping-top .discount-product {
  position: absolute;
  top: 10px !important;
  left: 15px;
  display: inline-block;
  color: #272727;
  background: #f7d807;
  padding: 4px 15px;
  text-align: center;
  line-height: 20px;
  font-size: 11px;
  text-transform: capitalize;
  text-align: center;
  z-index: 4;
  font-weight: 700;
}


.pos_content {
  position: relative;
}

/* style product */
.one_line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



.product-price-and-shipping {
  line-height: 1;

  .sr-only {
	display: none;
  }

  .regular-price {
	margin: 10px 0;
	color: #000000;
	font-weight: 500;
	font-size: 12px;
  }

}




/* category */
#js-product-list-top {
  ul.display {
	float: left;
	margin-right: 50px;
	position: relative;
	z-index: 1;
	@media (min-width: 768px) and (max-width: 991px) {
	  margin-right: 10px;
	}
	@media (max-width: 767px) {
	  margin: 0;
	}

	li {
	  float: left;
	  line-height: 30px;
	  height: 30px;
	  margin-right: 15px;
	  cursor: pointer;
	  color: #b6b6b6;
	  background: transparent;

	  &:last-child {
		margin-right: 0;
	  }

	  @include pos-transition(300ms);

	  &:hover, &.selected {
		@include pos-transition(300ms);
	  }

	  i {
		font-size: 18px;
		line-height: 30px;
	  }
	}
  }
}


/* page list */
.pagination .page-list {
  box-shadow: none;
  padding: 0;
  text-align: center !important;
}

.pagination {
  font-size: 18px;
  margin: 20px 0 50px 0;

  .previous, .next {
	float: none;
	display: inline-block;
	font-size: 0;
	height: 36px;
	line-height: 36px;
	padding: 0 4px;
	color: #9DA29B;

	&:hover {
	  color: lighten(#9DA29B,15%);
	}

  }

  a {

	padding: 0;
	font-size: 18px;
	display: inline-block;
	border-radius: 100%;
	text-align: center;
	color: #9DA29B;
	font-weight: $font-weight-bold;

	i {
	  font-size: 18px;
	}

	&:hover {
	  color: #6BCC4F;
	}
  }

  .current a {
	color: #6BCC4F;
  }

  .show_items {
	line-height: 28px;
	font-size: 15px;
  }


}


.product_content {
  position: relative;
  margin: 0;
  z-index: 0;

  &.list {
	.item-product {
	  padding: 0;
	}

	.js-product-miniature {
	  margin-bottom: 30px;
	  padding: 15px;

	  .row {
		margin: 0;
	  }

	  .img_block {
		padding: 0;
		overflow: hidden;

		.add-to-links {
		  display: none;
		}

	  }

	  .product_desc {
		padding-left: 30px;
		padding-top: 20px;
		padding-bottom: 0;
		text-align: left;
		margin: 0 !important;
		@media (min-width: 992px) {
		  width: 100%;
		  padding-right: 230px;
		}
		@media (max-width: 479px) {
		  padding: 20px;
		}

		.manufacturer {
		  line-height: 13px;

		  a {
			margin-bottom: 0;
		  }
		}

		.box {
		  ul.add-to-links {
			display: block;
		  }

		  @media (min-width: 992px) {
			position: absolute;
			left: auto;
			right: 0;
			top: 50%;
			height: auto;
			padding-left: 25px;
			max-width: 220px;
			min-height: 226px;
			margin: 0;
			padding-top: 30px;
			-moz-transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		  }

		  .availability {
			display: block;
		  }
		}

		.product-desc {
		  display: block;
		  padding: 5px 0;
		  margin: 0;
		  font-size: 13px;
		  color: #343434;
		  line-height: 20px;

		  p {
			font-size: 13px;
			color: #343434;
			line-height: 24px;
			padding: 0;
		  }
		}

		.availability {
		  display: none;
		}

		div.cart {
		  opacity: 1;
		  display: inline-block;
		  vertical-align: top;
		  margin-bottom: 10px;
		  position: static;
		  transform: none;
		  visibility: visible;
		}

		.add-to-links {
		  position: static;
		  background: transparent;
		  transform: none;
		  text-align: center;
		  opacity: 1;
		  visibility: visible;
		  box-shadow: none;
		  display: inline-block;
		  vertical-align: top;
		  padding: 0;
		  box-shadow: none;
		  border: none;
		  @media (max-width: 991px) {
			text-align: left;
		  }

		  li {
			display: inline-block;
			clear: none;
			height: auto;
			transform: none !important;
			opacity: 1 !important;
			visibility: visible !important;
			margin-right: 10px;
			border: none;

			&:last-child {
			  margin: 0;
			}

			a {
			  font-size: 0;
			  width: 36px;
			  border: none;
			  box-shadow: none;
			  background: #e8e8e8;
			  height: 36px;
			  line-height: 36px;
			  border: none;
			  border-radius: 100%;

			  &:hover {
				color: #ffffff;
			  }

			  span {
				display: none;
			  }

			}

			&:last-child {
			  margin: 0;
			}

			&.cart {
			  display: none;
			}
		  }
		}

		.product-price-and-shipping {
		  transform: none !important;
		  opacity: 1 !important;
		  visibility: visible !important;

		  .regular-price {
			font-size: 18px;
		  }

		  .price {
			font-size: 24px;
		  }
		}

		.variant-links {
		  margin-top: 30px;
		}

	  }

	  @media (max-width: 479px) {
		.img_block, .product_desc {
		  width: 100%;
		}
	  }
	}

	.style_product2 {
	  .product_desc {
		.box {
		  display: block;
		}
	  }
	}
  }

}


@media (min-width: 360px) and (max-width: 543px) {
  #js-product-list .product_content.grid .item-product {
	float: left;
	width: 50%;
  }
}


.product-settings {
  margin-top: 1rem;
  @include media-breakpoint-up(md) {
	margin-top: 5rem;
  }
}


/* end grid list */
/* end category */
/*  */
/* page */
.clearfix {
  clear: both;
}

#wrapper, #notifications {
  display: block !important;
}


/* end page */
/* detail */
#product #content {
  max-width: 100%;
}

.images-container, .images-quickviews {
  max-width: 100%;
  margin: auto;
  position: relative;
  margin-bottom: 30px;

  img {
	display: none;
  }

  .slick-slider img {
	display: block;
  }
}

.product-images {

  .slick-prev, .slick-next {
	&:before {
	  color: #343434;
	  opacity: 1;
	}

	&:hover {
	  &:before {
		color: $primary;
	  }
	}
  }

  .slick-list {

	margin-left: -20px;
	margin-right: -20px;

	.slick-track {
	  margin-left:0;
	}
  }

  .thumb-container {
	position: relative;
	cursor: pointer;
	margin: 0 20px;

	> div {
	  background: #ffffff;
	  position: relative;
	  @include pos-transition(300ms);
	  opacity: 5;
	  border: 3px solid transparent;
	}

	&.slick-current > div, &:hover > div {
	  opacity: 1;
	  @include pos-transition(300ms);
	  border: 3px solid #6BCC4F;
	}

	img {
	  width: 100%;
	}
  }

}





/* end vertical left */

.product-cover {
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;

  img {
	box-shadow: none;

  }



  &:hover {
	&:before {
	  opacity: 1;
	  background: rgba(255, 255, 255, 1);
	  @include pos-transition(300ms);
	}
  }
}

.content_info {
  display: inline-block;
  width: 100%;
  //margin-bottom: 60px;
  padding: 30px 20px 10px 20px;
}


.namne_details {
  font-size: 30px !important;
  line-height: 1.2 !important;
  font-weight: 700 !important;
  color: #202020 !important;
  text-transform: uppercase !important;
}

.product_name {
  font-size: 26px;
  line-height: 1.2;
  font-weight: $font-weight-bold;
  color: #202020;
}

.product_name_box {
  padding: 5px 15px;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;
  color: #202020 !important;
}

.reference {
  font-size: 15px;
  color: #69696c;
}


.product-discounts {
  margin: 0;
}

.product-quantity {
  display: block;

  .qty {
	margin-right: 10px;
  }

}


.tab-content {
  overflow: hidden;
  font-size: 15px;
  background: #ffffff;
  line-height: 24px;
  text-align: left;
  padding: 40px 30px;
}


#tab-content {
  font-size: 15px;

  label {
	font-size: 15px;
  }
}




.quickview .product-cover:before {
  display: none;
}

.panel-product-line {
  display: inline-block;
  vertical-align: top;
  float: left;


}

.quickview #product_comments_block_extra .comments_advices {
  display: none;
}





/* end detail */


#main .page-footer {
  margin: 0;
}


.alert {
  margin-top: 20px;
}





  .ajax_add_to_cart_button.disabled {
	background: #d2d2d2;
  }




/// Table product


#content-wrapper {
  width: 100%;
}





///// Card


.page-cart {

  #notifications {
	margin-top: 90px;
  }

}


.product-line-actions {

  @include media-breakpoint-up(md) {

	display: flex;
	align-items: center;
	justify-content: center;

	.row {
	  align-items: center;
	}
  }

}


.checkout-login {
  background: #202020;
  border-radius: 0 !important;
  border: 0 !important;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 14px;
}



.page-header h1 {
  font-weight: $font-weight-medium;
  text-align: center;
  font-size: 1.8rem;
  margin: 1em 0;
  line-height: 1.1em;

  @include media-breakpoint-up(sm) {
	font-size: 2.9rem;
  }
}


.section-title {

  a {
	font-weight: $font-weight-bold;
	line-height: 1.2;
  }

  margin: 50px 0;
  font-weight: $font-weight-bold;
  line-height: 1.2;
  @include smart-responsive('font-size',32,18);
}





.back a {
  color: #202020;
  font-weight: 600;

  &:hover {
	color: #202020;
  }
}


.pa-xs-reset {
  @include media-breakpoint-down(sm) {
	padding-left: 0 !important;
	padding-right: 0 !important;
  }
}


/// todo refractor
.lazyload {
  border: 0;
  opacity: 0;
  @include transition(all .6s cubic-bezier(0.2, 0.3, 0, 1));


  &.loaded {
	opacity: 1;
	@include transition(all .6s cubic-bezier(0.2, 0.3, 0, 1));
  }


}


.pack-product-name {


  font-size: 0.84rem;

  @include media-breakpoint-up(sm) {
	font-size: 0.87rem;
  }

  max-width: 350px;
  line-height: 1.3 !important;

  a {
	line-height: 1.2 !important;
	font-weight: 600;
	color: #232323 !important;
  }

}

.pack-product-price {
  margin-left: auto;

  span {
	width: 100%;
	display: block;
	text-align: right;
  }
}

#customer-form {
  .form-control-comment {
	display: none;
  }
}


input:invalid {
  box-shadow: 0 0 0 transparent !important;
}


.hover-animate {
  opacity: 1;
  @include transition(all 0.2s cubic-bezier(0.2, 0.3, 0, 1));

  &:hover {
	opacity: 0.55;
	@include transition(all 0.2s cubic-bezier(0.2, 0.3, 0, 1));
  }

}




.psgdprinfo17 {
  background: transparent;
  border: 1px solid #eeeeee;
  display: table;
  padding: 25px;

  p {
	margin-bottom: 1rem;
  }

}

#exportDataToCsv {
  margin-bottom: 1rem;
}

#exportDataToCsv, #exportDataToPdf {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}


.address-box .address {
  padding: 25px;

  border: 1px solid #eeeeee;
  height: 100%;
}


#notifications {
  width: 100%;
}


.w-20 {
  flex: 0 0 20%;
  max-width: 20%;
}



.all-product-link {
  color: #7a7a7a;
  font-weight: 500;
}


.b-contact-box {
  a {
	color: #303730 !important;
	font-weight: $font-weight-bold;
  }
}


.menu-item.menu-item13 {
  color: #5bbd3f;
}


.form-group.business,
.form-group.country {
  display: none;
}

#checkout .form-group.postcode {
  display: none;
}