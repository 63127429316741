.category-header {

  &__content {
	padding: 20px 0;

	@include media-breakpoint-up(lg) {
	  padding: 40px 0px;
	}

  }



  h1 {
	font-size: 50px;
	font-weight: $font-weight-bold;
	margin:0;
	text-transform: uppercase;
	margin-bottom: 1rem;
  }

}


//.breadcrumb_container {
//  padding:0;
//}


.breadcrumb {
  background: transparent;
  margin: 0;
  padding: 0;



  li::before {
	color: #9DA29B;
  }

  a {
	color: #9DA29B;
  }
}



/// Categories

.block-categories {

  .category-top-menu {
	margin-top: 20px;
  }

  ul {
	padding: 0;
  }




  //divdata-depth="1"


  //.

  .category-sub-menu {

	li {
	  padding: 6px 0;
	  font-size: 16px;
	}

	li[data-depth="0"] a strong {
	  font-size: 20px;
	  font-weight: $font-weight-medium;
  	}

	li[data-depth="1"] .category-sub-menu {
	  margin-left: 20px;

	  li:last-child {
		padding-bottom: 0;
	  }

	}

	&__wrap {
	  padding: 11px 0;
	}

  }




}


//// Search

#left-column {
  .block-search {
	margin-bottom: 40px;
  }
	.search_top form {
	  width: 100% !important;
	}
}

.block-search {
  width: 100%;
}

.search_top {
  display: flex;
  justify-content: center;
  width: 100%;
}

.search_top form {
  width: 100%;
  @include media-breakpoint-up(md) {
	width: 90%;
  }
  background: #ffffff;
  border: 2px solid #EDEDED;
  color: #000000;
  font-size: 15px;
  height: auto;
  line-height: 1.1;
  font-weight: 500;
  border-radius: 90px;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: space-between;

  input {
	border: 0;
	width: 100%;
	padding: 0.8rem 1.2rem 0.8rem 1.2rem;
	background: transparent;

	&::placeholder {
	  color: #000000;
	  opacity: 1;
	}

  }

  button {
	border: 0;
	line-height: 1;
	font-size: 20px;
	padding:0;
	cursor: pointer;
	margin-right: 20px;
	background: transparent;
  }



}

.active_filters {
  	padding-bottom: 30px;
	ul {
	  display: flex;
	  flex-wrap: wrap;

	  .filter-block {
		font-weight: $font-weight-medium;
		margin-right: 14px;
		font-size: 13px;
		background: #efefef;
		padding: 7px 18px;
		border-radius: 30px;
		margin-bottom: 10px;

		i {
		  margin-left: 8px;
		  font-size: 18px;
		  color: #000000;
		}

	  }

	}
}

.products-sort-order {
  font-size: 14px;
}


.category-description-seo {

  h1, h2, h3, h4, h5 {
	margin-bottom: 24px;
  }
  p {
	margin-bottom: 20px;
  }

}


.b-product {






  $c: &;

  position: relative;;

  &__inner {
	background: #ffffff;
	position: relative;
	display: flex;
  }

  &__discount, &__price {
	line-height: 1.2;
	span {
	  line-height: 1.2;
	}
  }

  img {
	transform: scale(0.95);
	transition: all 0.45s cubic-bezier(0.2, 0.3, 0, 1);
	display: table;
	margin: 0 auto;
  }


  &:hover {

	img {
	  transform: scale(1.06);
	  transition: all 0.35s cubic-bezier(0.2, 0.3, 0, 1);
	}

  }



  &__wrap {
	border: 1px solid #E4E4E4;
	border-radius: 15px;
	overflow: hidden;
  }

  &__category {
	padding-bottom: 10px;
	color: #F27E1C;
	font-size: 16px;
	font-weight: $font-weight-medium;
  }

  &__link {
	width: 100%;
	z-index: 1;
	overflow: hidden;
  }

  &__name {
	color: #303730;
	margin-bottom: 10px;

	a {
	  font-size: 22px;
	  color: #303730;
	  font-weight: $font-weight-bold;
	}

  }

  &__price {
	font-size: 20px;
	color: #303730;
	font-weight: $font-weight-bold;

	height: 48px;

  }




}




.product-quantity {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.product-quantity .add,
.product-quantity .qty {
  float: left;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 0.6rem;
}



.bootstrap-touchspin {
  width: 75px;
  //float: left;
  display: flex;
  border: 2px solid #EDEDED;
  border-radius: 14px;

  //border-top-left-radius: 30px;
  //border-bottom-left-radius: 30px;


  overflow: hidden;


  input {
	height: 100%;
	border: 0;
  }

  i {
	font-weight: $font-weight-bold;
	font-size: 18px;
  }

  .input-group-btn-vertical {
	position: relative;
	white-space: nowrap;
	display: flex;
	flex-direction: column;
	height: 100%;

	.btn {
	  display: block;
	  float: none;
	  width: 100%;
	  max-width: 100%;
	  padding: 1px 10px;
	  background: #EDEDED;
	  color: #000000;
	  position: relative;
	  @include transition($transition-base);

	  &:hover {
		background: #6bcc4f;
		color: #ffffff;
		@include transition($transition-base);
	  }

	}

	.bootstrap-touchspin-up {
	  border-bottom: 2px solid #ffffff;
	}

  }


  .icon-chevron-small-up:before {
	content: "+";
	line-height: 1.1;
  }

  .icon-chevron-small-down:before {
	content: "-";
  }


  .add, .qty {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-bottom: .5rem;
  }

  .add {
	flex: 1;
  }

  .qty {
	margin-right: 10px;
  }

  .btn-touchspin {
	height: 50%;
  }

}

.bootstrap-touchspin .group-span-filestyle .btn-touchspin,
.group-span-filestyle .bootstrap-touchspin .btn-touchspin {
  background: $primary;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.bootstrap-touchspin {
  width: auto;
  float: left;
  display: inline-block;
}

.bootstrap-touchspin input:focus {
  outline: none;
}

.bootstrap-touchspin input.form-control,
.bootstrap-touchspin input.input-group {
  color: #232323;
  background-color: #fff;
  padding: 0.175rem 0.5rem;
  width: 2.5rem;
  text-align: center;
  font-size: 17px;
  float: left;
}

#facet_28233 > li:nth-child(1) a {
  color: #6BCC4F;
}


#search_filters {
  font-weight: $font-weight-medium;
}

.search-filters {

  &__title {
	font-size: 26px;
	color: #303730;
	font-weight: 700;
	margin-top: 20px;
  }

  &__subtitle {
	margin-top: 10px;
	font-size: 18px;
  }
}



.product-flag {

  position: absolute;
  z-index: 2;
  top: 25px;
  left: 0;
  padding: 0;
  margin: 0;

  li {
	display: inline-block;
	padding: 4px 13px;
	margin-bottom: 4px;
	text-align: center;
	font-size: 0.85rem;
	font-weight: $font-weight-bold;
	cursor: default;

	-webkit-border-top-right-radius: 8px;
	-webkit-border-bottom-right-radius: 8px;
	-moz-border-radius-topright: 8px;
	-moz-border-radius-bottomright: 8px;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
  }

  .new, .on-sale {
	color: #ffffff;
	background: #f8d011;
  }

   .coming-soon {
	background: #9DA29B;
	color: #ffffff;
  }

  .bio {
	background: #6BCC4F;
	color: #ffffff;
  }

  .premium {
	background: #5e655d;
	color: #ffffff;
  }


	.discount {
	  background: #f17d1d;
	  color: #ffffff;
	}



  .pack {
	z-index: 2;
  }

  .on-sale {
	z-index: 3;
  }

}

.checkbox-checked-square__outer {
  border: 2px solid #eeeeee !important;
}

.checkbox-checked-square {
  width: 8px;
  height: 8px;
  background: #6BCC4F;
  margin-top: 2px !important;
  margin-left: 2px !important;
}

.active_filters {
  ul {
	padding: 0;
  }
}