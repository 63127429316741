.header-nav {
  background: #5BBD3F;
  color: #ffffff;
  font-size: 14px;
  position: relative;

  span {
	line-height: 1.2;
  }

  a {
	font-weight: $font-weight-medium;
	color: #ffffff;
	letter-spacing: -0.020rem;
	font-size: 16px;
	margin-right: 25px;
  }


  h3 {
	display: none;
  }

  ul {
	padding: 0;
  }

  .links ul {
	display: flex;

	li {
	  padding-right: 15px;

	  &:last-child {
		padding: 0;
	  }

	}

  }


  img {

	  height: 30px;
	  width: 30px;


  }

  &__inner {
	padding: 5px 0;
  }


}


.header {
  @include transition($transition-base);

  .b-social-follow {

	li {
	  margin: 0;
	  //padding-right: 6px;
	  @include border-radius(20px);
	  //background: #BDFE9A;

	  &:last-child {
		padding-right: 0;
	  }

	  a {
		display: flex;
	  	margin-right: 6px;
	  }

	  img {
		filter: invert(86%) sepia(37%) saturate(419%) hue-rotate(45deg) brightness(103%) contrast(99%);
	  }

	}

  }

  //
  //&.header-sticky {
	//border: 0;
	//position: fixed;
	//@include transition($transition-base);
  //
	//-webkit-animation-duration: calc(0.45s);
	//animation-duration: calc(0.45s);
  //
	//-webkit-animation-name: slideInDown;
	//animation-name: slideInDown;
  //
	//.header-nav {
	//  display: none !important;
	//}
  //
  //
  //}


}


@-webkit-keyframes slideInDown {
  from {
	-webkit-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0);
	visibility: visible;
  }

  to {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
  }
}


.header-top {
  @include transition($transition-base);

  padding: 36px 0;


  .content-cart {
	display: none;
  }


  .b-header-menu {

	ul {
	  padding: 0;
	  margin: 0;
	  font-family: $font-family-base;
	  //display: flex;
	  //justify-content: space-between;
	}

	li {
	  padding: 4px 12px;
	  display: inline-block;

	  &:first-child {
		padding-left: 0;
	  }

	  &.current a {

		color: #2A2A2A;

		&:after {
		  content: "";
		  position: absolute;
		  width: 100%;
		  height: 7px;
		  background-color: #2A2A2A;
		  bottom: -29px;
		  left: 0;
		  -webkit-transition: width 200ms ease-in-out;
		  transition: width 200ms ease-in-out;
		}

	  }

	}


	a {
	  font-size: 1rem;
	  padding: 0;
	  margin: 0;
	  color: #6A6A6A;
	  text-decoration: none;
	  position: relative;
	  height: 26px;

	  &:after {
		content: "";
		position: absolute;
		width: 0;
		height: 7px;
		background-color: #2A2A2A;
		bottom: -29px;
		left: 0;
		-webkit-transition: width 200ms ease-in-out;
		transition: width 200ms ease-in-out;

		-webkit-border-top-left-radius: 4px;
		-webkit-border-top-right-radius: 4px;
		-moz-border-radius-topleft: 4px;
		-moz-border-radius-topright: 4px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;

	  }

	  &:hover:after {
		width: 96%;
	  }

	}


  }


}


.header-nav.mobile {

  //position: fixed !important;
  //width: 100%;

  z-index: 1000;
  position: fixed;
  width: 100%;




}

#mobile_top_menu_wrapper {
  display: block !important;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-width: 350px;
  z-index: 99;
  margin: 0;
  padding: 0;
  webkit-transform: translate(-400px, 0px);
  -moz-transform: translate(-400px, 0px);
  -ms-transform: translate(-400px, 0px);
  -o-transform: translate(-400px, 0px);
  transform: translate(-400px, 0px);
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  -moz-transition-duration: .9s;
  -o-transition-duration: .9s;
  -webkit-transition-duration: .9s;
  transition-duration: .9s;
  overflow: auto;
}

#mobile_top_menu_wrapper.box-menu {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}


.header-nav #menu-icon {
  height: 55px;
  line-height: 63px;
  font-size: 32px;
  float: left;
}

.mobile-logo {
  margin: 0 auto;
  display: table;
  padding-top: 5px;
}

.open-menu:after {
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  display: block;
  position: fixed;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  transition: opacity 0.2s cubic-bezier(0, 0, 0.3, 1);
  transition-delay: 0.1s;
}

.menu-close {
  color: #303030;
  line-height: 50px;
  height: 50px;
  padding: 0 20px;
  font-size: 16px;
  text-transform: uppercase;
  pointer-events: none;
}

.menu-close i {
  line-height: 50px;
  display: block;
  height: 50px;
  pointer-events: visible;
  cursor: pointer;
}

.menu-tabs {
  width: 100%;
  height: 100%;
}


.selector-mobile {
  img {
	vertical-align: 0px;
	margin-right: 5px;
  }

  button {
	background: transparent;
	border: none;
	border-top: 1px solid #eeeeee;
	width: 100%;
	text-align: left;
	display: block;
	color: #2b2d2f;
	font-size: 13px;
	font-weight: 400;
	padding: 5px 20px;
	line-height: 30px;
	cursor: pointer;
	text-transform: uppercase;

	i {
	  position: absolute;
	  right: 20px;
	  top: 13px;
	  font-size: 18px;
	  color: #2b2d2f;
	}
  }

  .dropdown-menu {
	position: static;
	border: 0;
	padding: 0;
	margin: 0;
	float: none;

	li {
	  border-top: $main_border;

	  a {
		display: block;
		color: #2b2d2f;
		font-size: 12px;
		font-weight: 400;
		padding: 5px 20px;
		line-height: 30px;
		text-transform: uppercase;
	  }
	}
  }

}

.js-top-menu-bottom {
  display: inline-block;
  width: 100%;
  position: relative;

}


#_mobile_user_info .user-info-block .localiz_block .dropdown-menu {
  top: 100%;
}


.header-nav .mobile {
  > .row-mobile {
	.mobile-bottom {
	  display: flex;
	  justify-content: space-around;
	}
  }
}

/* end nav */


.header-bottom {
  background: darken(#efefef,2%);
}


.pos-menu-horizontal .menu-content {

  display: table;

  .menu-item > a {
	font-size: 16px;
	font-weight: $font-weight-medium;
  }
}





.menu-dropdown {

  .submenu-item {
	a {
	  font-weight: $font-weight-medium !important;
	  padding: 5px 0;
	  font-size: 16px;
	}
  }


  .category-sub-menu {
	a {
	  font-weight: $font-weight-light !important;
	  text-transform: none !important;

	  &.active {
		font-weight: $font-weight-medium !important;
		text-decoration: underline;
	  }
	}
  }


}


#_mobile_search_category {
  margin: 5px 0;
}

#pos_search_top .form_search .form-control {
  height: 42px;
}


.icon-drop-mobile {
  .icon-chevron-down {
	display: block;
  }

  .icon-chevron-up {
	display: none;
  }

  &.open_menu {
	.icon-chevron-up {
	  display: block;
	}

	.icon-chevron-down {
	  display: none;
	}

  }


}


#_mobile_megamenu {
  a {
	color: #202020;
  }
}


.open-submenu {
  .submenu-item > a {
	display: none !important;
  }

  .category-sub-menu {
	display: block !important;
  }
}


.b-shopping-menu {
  margin-top: 10px;

}


#_mobile_megamenu {
  height: calc(100vh - 60px);
  max-height: calc(100% - 50px);
  width: 100%;

  .menu-content {
	width: 100%;
  }
}

.menu-content {
  padding:0;
}



#_desktop_megamenu {

  .menu-item {
	> a {
	  display:block;
	  font-weight: $font-weight-medium;
	  color: #5B675B;
	  letter-spacing: -0.020rem;
	  font-size: 17px;
	  padding: 19px 0;

	  img {
		display: inline-block;
	  }

	  &:after {
		content: "";
		position: absolute;
		width: 0;
		height: 4px;
		background-color: #6BCC4F;
		top: 0;
		left: 0;
		-webkit-transition: width 200ms ease-in-out;
		transition: width 200ms ease-in-out;
	  }

	  &:hover {
		color: #111111;

		&:after {
		  width: 100%;
		}
	  }


	}

	&.active > a:after {
	  width: 100%;

	}

  }


  &.current a {

	&:after {
	  content: "";
	  position: absolute;
	  width: 100%;
	  height: 2px;
	  background-color: #d2d2d2;
	  bottom: 2px;
	  left: 0;
	  -webkit-transition: width 200ms ease-in-out;
	  transition: width 200ms ease-in-out;
	}

  }

}


///


.header-mobile-top {

  .content-cart {
	display: none;
  }

}


.cart-preview {
  .shop-item {
	margin-left: 20px;

  }
}


.b-header-menu {
  .category div {
	display: none;
  }
}


.b-product .quick_view {
  img {
	margin:0;
	margin-left: auto !important;
	display: block;
  }
}




#wrapper > .bg-mask {
  display: none;
}

#_desktop_megamenu .pos-menu-horizontal .menu-content .menu-dropdown .pos-sub-inner {
  background: #E5E5E5 !important;
}