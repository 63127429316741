.h-auto {
  height: auto;
}

.h-100 {
  height: 100%;
}

.bg-cover {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-mask {
  content: '';
  position: absolute;
  background: rgba(13, 13, 13, .4);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  @include transition($transition-base);
}

.row-full {

  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;

}

.pv {
  padding-top: 60px;
  padding-bottom: 60px;

  @include media-breakpoint-up(md) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}







#pos_search_top, #search_widget {

  form {
    border: 1px solid #E4E4E4;
    background: #F9F9F9;
    display: flex;
    justify-content: space-between;
    @include border-radius(10px);


    input {
      padding: 20px 30px;
      font-size: 14px;
      color: #5B675B;
      border: 0;
      background: transparent;

    }

    button {
      border: 0;
      line-height: 1;
      font-size: 20px;
      padding: 0;
      cursor: pointer;
      margin-right: 20px;
      background: transparent;
    }

  }

}

#search_widget {
  form {

    input[type="text"] {



      width: 80%;

      @include media-breakpoint-up(md) {
        width: 60%
      }



    }

  }
}


.bg-grey {
  background: #F5F5F5;
}

