.modal {
  //color: #232323;

  .modal-header {
	background: #ffffff !important;
	border-bottom: 1px solid #e5e5e5;
  }

  .modal-body {
	background: #ffffff;
	padding: 1.5rem 2rem;
  }

  .modal-dialog {
	max-width: 750px;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 24px;
	width: 100%;
  }

  .product-image {
	width: 100%;
  }

  .modal-title {
	font-weight: 700;
	color: #202020;
	font-size: 1.125rem;
  }

  button.close {
	color: #202020;
	opacity: 1;
  }

  .product_name {
	font-size: 1.8rem;
	font-weight: $font-weight-bold;
	margin-bottom: 5px;
	display: inline-block;
	color: #202020;
  }

  .product_variant {
	width: 100%;
	display: block;
	text-align: center;
	font-size: 1rem;
	font-weight: $font-weight-light;
	color: #000;
  }

  .product-price-small {
	font-size: 1.4rem;
	font-weight: $font-weight-medium;
	padding: 1.5rem 0 1rem;
  }

  .cart-products-count {
	font-size: 1.125rem;
  }

  .cart-content {
	padding-left: 2.5rem;
  }

  .cart-content .cart-content-btn {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
  }

  .cart-content .cart-content-btn button {
	margin-right: 0.3125rem;
  }

  .cart-content .cart-content-btn .btn {
	white-space: inherit;
  }

  .btn {
	display: inline-block;
	width: 100%;
  }

}


.blockcart {
  position: relative;

  .button_cart {
	position: relative;
	display: inline-block;
  }

  .discount {
	background: transparent;
	order: 1;
	padding: 0;
	margin: 0;
	text-transform: none;
  }

  a.desktop, a.mobile {
	display: block;
	position: relative;
	font-size: 12px;
	text-align: center;

	span.text {
	  @media (max-width: 1024px) {
		display: none;
	  }
	}

	.item_count {
	  width: 20px;
	  height: 20px;
	  line-height: 20px;
	  font-size: 11px;
	  background: #6bcc4f;
	  color: #ffffff;
	  text-align: center;
	  font-weight: $font-weight-medium;
	  border-radius: 100%;
	  -webkit-border-radius: 100%;
	  position: absolute;
	  top: -11px;
	  right: 0px;
	}
  }


  .btn-primary {
	width: 100%;
	text-align: center;
  }

  ul {
	margin: 0;
	padding: 0 18px;

	li {
	  padding: 20px 0;
	  border-bottom: $main_border;
	  overflow: hidden;

	  &:last-child {
		border: 0;
	  }

	  .img_content {
		background: $white;
		float: left;
		margin: 0 10px 0 0;
		position: relative;
		width: 85px;

		.product-quantity {
		  position: absolute;
		  top: 5px;
		  left: 5px;
		  min-width: 20px;
		  line-height: 20px;
		  -webkit-border-radius: 100%;
		  -moz-border-radius: 100%;
		  border-radius: 100%;
		  padding: 0;
		  text-align: center;
		  font-size: 12px;
		  display: inline-block;
		}
	  }

	  .right_block {
		overflow: hidden;
		position: relative;
		padding: 0;

		.product_name {
		  display: block;
		  font-weight: $font-weight-bold;
		  font-size: 16px;
		  margin-bottom: 10px;
		}

		.product-price {
		  display: block;
		  margin: 5px 0 0;
		  font-size: 16px;
		  font-weight: $font-weight-medium;
		}

	  }
	}
  }

  .price_content {
	overflow: hidden;
	padding: 20px 18px;

	.price_inline {
	  overflow: hidden;
	  line-height: 25px;
	  font-weight: 400;

	  .label {
		float: left;
		font-size: 15px;
	  }

	  .value {
		float: right;
		font-size: 15px;
	  }

	  &.cart-total {

		.label {
		  font-weight: $font-weight-medium;
		}

		.value {
		  font-size: 18px;
		  font-weight: $font-weight-medium;
		}
	  }
	}
  }

  .checkout {
	padding: 0 18px 30px 18px;

  }
}


@media (min-width: 992px) {
  .blockcart ul {
	max-height: 271px;
	overflow-y: auto;


  }
}

.blockcart ul::-webkit-scrollbar-track {
  background-color: #2b2d2f;
}

.blockcart ul::-webkit-scrollbar {
  width: 4px;
  background-color: #2b2d2f;
}

.blockcart ul::-webkit-scrollbar-thumb {
  background-color: rgba(46, 46, 46, 0.3);
  border-radius: 10px;
}

///* style layer cart */
//#blockcart-modal .modal-body {
//  background: #ffffff;
//  padding: 3.125rem 1.875rem;
//}


.modal .cart-content {
  p, strong {
	font-size: 1rem;
  }

}

