
$text-color-primary: #000000;

ol, ul, dl {
  margin: 0;
  list-style-type: none;
}

a {
  color: #69696c;
  font-weight: $font-weight-medium;

  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;

  &:hover, &:focus {
	outline: 0 !important;
	text-decoration: none;
    color: #6BCC4F;
  }

}

.green a {
  color: #6BCC4F !important;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: none;
}

h1 {
  text-transform: uppercase;
}

* {
  &:focus {
	outline: none !important;
  }
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

#content.page-cms {

  padding-right: 22px;
  padding-left: 22px;

  padding-bottom: 5rem;

  ul, ol {

    list-style: none !important;
    padding:0;

    li {

      position: relative;
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: -0.01rem;
      padding-top: 9px;
      line-height: 1.2em;
      padding-bottom: 9px;
      padding-left: 30px;


      &:before {
        position: absolute;
        top: 7px;
        left: 0;
        margin-right: 0.2rem;
        content: "\e910";
        font-family: 'in' !important;
      }

    }
  }

  p {
    margin-bottom: 16px;
  }

}



#product_content .product-description {

  ul, ol {

    list-style: disc;
    padding-left: 1.8rem;
    padding-bottom: 1.2rem;

    li {

      position: relative;
      font-size: 1rem;
      padding-top: 9px;
      line-height: 1.2em;
      padding-bottom: 9px;
      padding-left: 10px;

    }
  }

  p {
    padding-bottom: 1.4rem;
    margin:0;
  }

}

.m-mask {
  content: '';
  position: absolute;
  background: rgba(0 ,0 ,0, .6);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

@mixin smart-responsive($elm, $max, $min) {
  #{$elm}: calc(#{$min}px + (#{$max} - #{$min}) * ((100vw - 320px) / (1400 - 320)));
}


.m-qty {
  border: 2px solid #EDEDED;
  border-radius: 14px;
  overflow: hidden;

  input {
    border:0;
    background: transparent;
    height: 100%;
    color: #232323;
    padding: 0.175rem 0.5rem;
    width: 2.5rem;
    text-align: center;
    font-size: 17px;
  }

  &__btn {

    display: block;
    width: 100%;
    padding: 1px 10px;
    position: relative;
    transition: all 0.2s ease-in-out;
    border:0;
    height: 100%;
    background: darken(#EDEDED, 2%);
    color: #000000;
    cursor: pointer;

    &:hover {
      background: lighten(#EDEDED,4%);
    }

  }


}


input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}


.bl {
  border-bottom: 1px solid #EDEDED;
}

.remove-product {
  padding-left: 0;
  text-align: left;
  font-size: 13px;

  &__label {
    font-size: 13px;
  }

}


.bootstrap-filestyle {

  input {
    border: 1px solid #e5e5e5;
    border-radius: .2rem;
    background: #ffffff !important;
  }

  .btn {
    background: #6bcc4f;
  }

}